import Google from "../../../assets/google.png";
import { useLocation, Link } from "react-router-dom";
import { getGoogleUrl } from "../../../utils/Google";
import "./style.css";
const GoogleButton = () => {
  const location = useLocation();
  let from = location.state?.from?.pathname || "/";

  return (
    <a className="google" href={getGoogleUrl(from)}>
      <img src={Google} alt="Google log in button" />
      Log in with Google
    </a>
  );
};

export default GoogleButton;
