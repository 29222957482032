import { InfinitySpin } from "react-loader-spinner";
import { handleFetchData } from "../../API/HandleRequests";
import { commentsRoute } from "../../utils/APIRoutes";
import Rate from "../AvisComponents/Rate";
import NewComment from "./Form/CommentForm";
import "./comments.css";
import { useState, useEffect } from "react";
const CommentsSection = ({ id, rate }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const commentsPerPage = 6; // Nombre de commentaires par page
const [loading, setLoading] = useState(true)
  const [fetchedCommentsPages, setFetchedCommentsPages] = useState([]);
  const [comments, setComments] = useState([]);
 const [countComments, setCountComments] = useState(0)
  const [totalPages, setTotalPages] = useState(0);
  const [showForm, setForm] = useState(false);

  const handleNextPage = () => {
    // Vérifiez si la page suivante existe avant de passer à la page suivante
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePrevPage = () => {
    // Vérifiez si la page précédente existe avant de passer à la page précédente
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  useEffect(() => {
    const fetchComments = async () => {
      
      if (fetchedCommentsPages.includes(currentPage)) {
        return; // Comments already fetched, no need to fetch again
      }
      setLoading(true)
      const result = await handleFetchData(commentsRoute, {
        page: currentPage,
        commentsPerPage: commentsPerPage,
        product: id,
      });

      if (result.status === "ok") {
        setComments((prevComments) => [
          ...prevComments,
          ...result.data.comments,
        ]);
        setFetchedCommentsPages((prev) => [...prev, currentPage]);
        setTotalPages(Math.ceil(result.data.total / commentsPerPage));
        setCountComments(result.data.total)
      }
      setLoading(false)

    };

    if (id) {
      fetchComments();
    }
  }, [currentPage, id]);

  return (
    <div className="comments-section">
      <div className="header">
        <h1>LES AVIS CLIENTS</h1>
        <div>
          <Rate rate={rate} />
          <h3>Moyenne basée sur {countComments} avis</h3>
        </div>

        <button onClick={() => setForm(!showForm)}>ÉCRIRE UN AVIS</button>
      </div>
      {showForm && <NewComment id={id} />}

{loading && 

<div className="loadercontainer">
<InfinitySpin
  color="var(--main-color)"
  wrapperClassName="loadercontainer"
/>
</div>

}
    
      <div className="comments-container">
        {comments.length === 0 && <h3>Pas encore de commentaires.</h3>}
        {comments
          .slice((currentPage - 1) * commentsPerPage, comments.length)
          .map((comment) => {
            return <Comment comment={comment} />;
          })}
      </div>
      {totalPages > 0 && (
        <div className="pagination">
          {currentPage !== 1 && (
            <h3
              className="borderbtn"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              Page précédente
            </h3>
          )}

          <span>
            {currentPage} / {totalPages}
          </span>

          {currentPage !== totalPages && (
            <h3
              className="borderbtn"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Page suivante
            </h3>
          )}
        </div>
      )}
    </div>
  );
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const Comment = ({ comment }) => {
  return (
    <div className="comment">
      <Rate rate={comment.rate} />
      <h3>{comment.title}</h3>
      <span>{comment.username + " le " + formatDate(comment.createdAt)}</span>
      <p>{comment.content}</p>
    </div>
  );
};
export default CommentsSection;
