const endpoint = "https://franck-ebook-api.vercel.app" 
// user Routes
export const loginRoute = `${endpoint}/api/auth/login`;
export const registerRoute = `${endpoint}/api/auth/register`;
export const contactRoute = `${endpoint}/api/auth/contact`;
export const changeRoute = `${endpoint}/api/auth/change`;
export const userRoute = `${endpoint}/api/auth/user`;
export const resendRoute = `${endpoint}/api/auth/resend`;
export const forgetRoute = `${endpoint}/api/auth/forget`;
export const resetRoute = `${endpoint}/api/auth/reset`;

// comments Routes
export const commentsRoute = `${endpoint}/api/comments/get`;
export const postCommentRoute = `${endpoint}/api/comments/new`;

// admin Routes
export const newInforoute = `${endpoint}/api/admin/newInfo`;
export const getInfos = `${endpoint}/api/admin/getInfos`;
export const removeInfo = `${endpoint}/api/admin/removeInfo`;
export const getUnVisible = `${endpoint}/api/admin/getUnvisibleComments`;
export const getVisible = `${endpoint}/api/admin/getVisibleComments`;
export const toogleRoute = `${endpoint}/api/admin/toogle`;
export const deleteRoute = `${endpoint}/api/admin/delete`;
export const modifyProductRoute = `${endpoint}/api/admin/modifyProduct`;

// product Routes
export const metadataRoute = `${endpoint}/api/product/metadata`;
export const addProductRoute = `${endpoint}/api/product/add`;
export const mainProductRoute = `${endpoint}/api/product/main`;
export const getProductsRoute = `${endpoint}/api/product/products`;
export const paymentRoute = `${endpoint}/api/product/payment`;
export const downloadRoute = `${endpoint}/api/product/download`;
export const freeProductRoute = `${endpoint}/api/product/free`;