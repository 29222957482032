export const SET_VISIBLES = "SET_VISIBLES";
export const SET_UNVISIBLE = "SET_UNVISIBLE";
export const REMOVE_COMMENT_FROM_VISIBLES = "REMOVE_COMMENT_FROM_VISIBLES";
export const REMOVE_COMMENT_FROM_UNVISIBLES = "REMOVE_COMMENT_FROM_UNVISIBLES";
export const ADD_COMMENT_TO_VISIBLES = "ADD_COMMENT_TO_VISIBLES";
export const ADD_COMMENT_TO_UNVISIBLES = "ADD_COMMENT_TO_UNVISIBLES";
export const SET_PRODUCTS = "SET_PRODUCTS";
export const SET_PRODUCT = "SET_PRODUCT";
export const SET_SELECTED = "SET_SELECTED";
export const SET_PRODUCT_ID = "SET_PRODUCT_ID";

// Reducer
export const adminReducer = (state, action) => {
  switch (action.type) {
    case SET_PRODUCTS:
      return {
        ...state,
        products: { content: action.payload, loaded: true },
        comments: {
          ...state.comments,
          visibles: {
            ...state.comments.visibles,
            loaded: false,
          },
          unvisibles: {
            ...state.comments.unvisibles,
            loaded: false,
          },
        },
      };

    case SET_PRODUCT_ID:
      return {
        ...state,
        comments: {
          ...state.comments,
          visibles: {
            comments: [],
            loaded: false,
          },
          unvisibles: {
            comments: [],
            loaded: false,
          },
        },
        product: { ...state.product, id: action.payload, loaded: false },
      };

    case SET_PRODUCT:
      return {
        ...state,
        product: { ...state.product, ...action.payload, loaded: true },
      };

    case SET_SELECTED:
      return {
        ...state,
        selected: action.payload,
      };

    case SET_VISIBLES:
      return {
        ...state,
        comments: {
          ...state.comments,
          visibles: {
            ...state.comments.visibles,
            comments: [...state.comments.visibles.comments, ...action.payload],
            loaded: true,
          },
        },
      };

    case SET_UNVISIBLE:
      return {
        ...state,
        comments: {
          ...state.comments,
          unvisibles: {
            ...state.comments.unvisibles,
            comments: [
              ...state.comments.unvisibles.comments,
              ...action.payload,
            ],
            loaded: true,
          },
        },
      };

    case REMOVE_COMMENT_FROM_VISIBLES:
      return {
        ...state,
        comments: {
          ...state.comments,
          visibles: {
            ...state.comments.visibles,
            comments: state.comments.visibles.comments.filter(
              (comment) => comment._id !== action.payload
            ),
          },
        },
      };
    case REMOVE_COMMENT_FROM_UNVISIBLES:
      return {
        ...state,
        comments: {
          ...state.comments,
          unvisibles: {
            ...state.comments.unvisibles,
            comments: state.comments.unvisibles.comments.filter(
              (comment) => comment._id !== action.payload
            ),
          },
        },
      };
    case ADD_COMMENT_TO_VISIBLES:
      return {
        ...state,
        comments: {
          ...state.comments,
          visibles: {
            ...state.comments.visibles,
            comments: [action.payload, ...state.comments.visibles.comments],
          },
        },
      };
    case ADD_COMMENT_TO_UNVISIBLES:
      return {
        ...state,
        comments: {
          ...state.comments,
          unvisibles: {
            ...state.comments.unvisibles,
            comments: [action.payload, ...state.comments.unvisibles.comments],
          },
        },
      };
    default:
      return state;
  }
};
