import { useNavigate, useParams } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import Back from "./Backbtn";
import { handlePostRequest } from "../../API/HandleRequests";
import { resetRoute } from "../../utils/APIRoutes";
const ChangeForm = () => {
  const { token } = useParams();

  const navigate = useNavigate();
  const [values, setValues] = useState({
    password: "",
    password2: "",
    reset: token,
  });
  const handleSubmit = async (e) => {
    e.preventDefault();

    const result = await handlePostRequest(resetRoute, values);

    if (result === "ok") {
      setValues({
        password: "",
        password2: "",
      });
      setTimeout(() => {
        navigate("/logs");
      }, 1000);
    }
  };

  const progress = useRef(undefined);
  useEffect(() => {
    const passwordLength = values.password.length;
    progress.current.classList.remove("bad", "ok", "good");

    if (passwordLength > 1 && passwordLength < 8) {
      progress.current.classList.add("bad");
    } else if (passwordLength >= 8 && passwordLength < 12) {
      progress.current.classList.add("ok");
    } else if (passwordLength >= 12) {
      progress.current.classList.add("good");
    }
  }, [values.password]);

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  return (
    <div className="forget">
      <form
        action="
      "
        onSubmit={handleSubmit}
      >
        {" "}
        <Back txt={"Retour au site"} route={"/"} />
        <h1>
          FRANCK<span>.</span>RCRD
        </h1>
        <p>Réinitialiser votre mot de passe...</p>
        <div className="fields">
          <div className="field">
            <label htmlFor="">MOT DE PASSE</label>
            <input
              type="password"
              placeholder="Entrez votre mot de passe"
              name="password"
              value={values.password}
              onChange={handleChange}
            />
            <div className="strongpassword">
              <div className="progress" ref={progress}></div>
            </div>
          </div>
          <div className="field">
            <label htmlFor="">RETAPEZ VOTRE MOT DE PASSE</label>
            <input
              type="password"
              placeholder="Retapez ici"
              value={values.password2}
              name="password2"
              onChange={handleChange}
            />
          </div>
        </div>
        <button type="submit">CHANGER LE MOT DE PASSE</button>
      </form>
    </div>
  );
};

export default ChangeForm;
