const routeConfig = {
  "/": {
    title: "Accueil - FRANCK.RCRD",
    keywords:
      "musculation, santé, développement personnel, voyage captivant, meilleur moi, ressources enrichissantes, univers des connaissances, croissance personnelle.",
    description:
      "Franck Richard - Explorez un voyage captivant dans le monde de la musculation, de la santé et du développement personnel. Découvrez les secrets pour façonner votre meilleur moi à travers des ressources enrichissantes. Plongez dans l'univers des connaissances et de la croissance personnelle.",
  },

  "/logs": {
    title: "Page de connexion - FRANCK.RCRD",
    keywords:
      "historique détaillé, compte, parcours, transformation personnelle, progrès, tendances, enseignements précieux, excellence.",
    description:
      "Franck Richard - Parcourez l'historique détaillé de votre compte, capturant chaque étape de votre parcours vers la transformation personnelle. Suivez vos progrès, analysez les tendances et tirez des enseignements précieux pour guider votre chemin vers l'excellence.",
  },
  "/ebook": {
    title: "Manifeste de l'Efficacité - FRANCK.RCRD",
    keywords:
      "Manifeste de l'Efficacité, musculation, bien-être, développement personnel, principes éprouvés, connaissances puissantes, corps fort, santé rayonnante, vie épanouissante.",
    description: `Franck Richard - Plongez dans le "Manifeste de l'Efficacité" de Franck Richard. Explorez les principes éprouvés de la musculation, du bien-être et du développement personnel. Découvrez des connaissances puissantes pour cultiver un corps fort, une santé rayonnante et une vie épanouissante grâce à cet ouvrage essentiel.`,
  },
  "/contact": {
    title: "Me contacter - FRANCK.RCRD",
    keywords:
      "formulaire de contact, échanger, questions, commentaires, collaborations, objectifs, santé, bien-être, croissance personnelle.",
    description: `Franck Richard - Restez connecté. Utilisez notre formulaire de contact pour échanger avec nous. Que ce soit pour des questions, des commentaires ou des collaborations, nous sommes là pour vous aider à atteindre vos objectifs de santé, de bien-être et de croissance personnelle.`,
  },
  "/user": {
    title: "Espace personnel - FRANCK.RCRD",
    keywords:
      "compte client, espace personnel, informations, personnalisation, préférences, parcours, transformation, contrôle total, cheminement, vie équilibrée, épanouissante.",
    description: `Franck Richard - Gérez votre espace personnel avec aisance. Accédez à vos informations, personnalisez vos préférences et suivez votre parcours vers la transformation. Expérimentez le contrôle total sur votre cheminement vers une vie équilibrée et épanouissante.`,
  },
  "/about": {
    title: "À propos de moi - FRANCK.RCRD",
    keywords:
      "Franck Richard, parcours, passion, musculation, santé, développement personnel, engagement, connaissances, expérience, bien-être, transformation personnelle.",
    description: `Franck Richard - Explorez mon parcours et ma passion pour la musculation, la santé et le développement personnel. Découvrez pourquoi je m'engage à partager mes connaissances et mon expérience pour vous aider à atteindre vos objectifs de bien-être et de transformation personnelle.`,
  },
  "/mentions": {
    title: "Mentions Légales - FRANCK.RCRD",
    keywords:
      "Franck Richard, parcours, passion, musculation, santé, développement personnel, engagement, connaissances, expérience, bien-être, transformation personnelle.",
    description: `Franck Richard - Mentions légales et politique de confidentialité L'entreprise individuelle FRANCK.RCRD, soucieuse des droits des individus, notamment au regard des traitements automatisés et dans une volonté de transparence avec ses clients, a mis en place une politique reprenant l’ensemble de ces traitements, des finalités poursuivi`,
  },
};

export default routeConfig;
