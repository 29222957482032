import { contactRoute } from "../../utils/APIRoutes";
import MediasComponent from "../Medias/MediaComponent";
import "./contactform.css";
import { handlePostRequest } from "../../API/HandleRequests";
import { useState } from "react";
import 'react-phone-number-input/style.css'
import PhoneInput, {isPossiblePhoneNumber } from 'react-phone-number-input'
import { toast } from "react-toastify";
import { toastOptions } from "../../Functions";


const ContactForm = () => {
  const [values, setValues] = useState({
    name: "",
    email: "",
    content: "",
  });

  const [phone, setPhone] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(!isPossiblePhoneNumber(phone)){
      toast.error("Le numéro de téléphone n'est pas valide.", toastOptions)
      return ;
    } 

    const toSend = {...values, phone : phone}

    const result = await handlePostRequest(contactRoute, toSend);
    if (result === "ok") {
      setValues({
        name: "",
        email: "",
        content: "",
        contact: JSON.parse(localStorage.getItem("contact")),
      });

      localStorage.setItem("contact", true);
    }
  };

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  return (
    <div className="contact-container">
      {" "}
      <form action="" onSubmit={handleSubmit}>
        <h1>FORMULAIRE DE CONTACT</h1>
        <p>Envie de travailler avec moi, une question à poser ?</p>
        <div className="row">
          <div className="fields">
            {" "}
            <div className="field">
              <input
                type="text"
                placeholder="Nom"
                name="name"
                value={values.name}
                onChange={handleChange}
              />
            </div>
            <div className="field">
              <input
                type="text"
                placeholder="Adresse mail"
                name="email"
                value={values.email}
                onChange={handleChange}
              />
            </div>
            <PhoneInput
      placeholder="Numéro de téléphone"
      defaultCountry="FR"
      name="phone"
      rules={{ required: true}}
      value={phone}
  
      onChange={(phone) => setPhone(phone)}/>
            
            <div className="field">
              <textarea
                type="text"
                placeholder="Votre message ici..."
                name="content"
                value={values.content}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="medias">
            <MediasComponent />
          </div>
        </div>{" "}
        <button type="submit">ENVOYER LE MESSAGE</button>
      </form>
    </div>
  );
};

export default ContactForm;
