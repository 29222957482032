import ebook from "../assets/ebook.webp";
import Rate from "../components/AvisComponents/Rate";
import CommentsSection from "../components/Comments/CommentsSection";
import {  paymentRoute } from "../utils/APIRoutes";
import { handlePostRequest } from "../API/HandleRequests";
import Price from "../components/Price/Price";
import PopUp from "../components/PopUp/PopUp";
import { useState } from "react";
import { toast } from "react-toastify";
import { toastOptions } from "../Functions";
import Form from "../components/FreeExtrait/Form";

const Ebook = ({ product }) => {
  const [values, setEmail] = useState({ email: "", email2: "" });
  const handleCheckOut = async () => {
    const isLoggedIn = document.cookie.includes("logged_in=true");

    if (
      !localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY) &&
      !isLoggedIn
    ) {
      setPopUp(true);
      return;
    }
    request();
  };


  const [displayEmail, setDisplayEmail] = useState(false)
 

  const request = async () => {

    /*
    CODE POUR MAINTENANCE 
    toast.info("Pas disponible pour le moment", toastOptions);

    return ; */

    if (values.email !== values.email2) {
      toast.error("Les deux adresses sont différentes.", toastOptions);
      return;
    }

    const response = await handlePostRequest(paymentRoute, {
      productID: product.id,
      email: values.email,
    });
    if (response === "ok") {
      setPopUp(false);
    }
    setEmail("");
  };
  const [popup, setPopUp] = useState(false);


  return (
    <div className="ebook fragment">
      {popup && (
        <PopUp
          setPopUp={setPopUp}
          request={request}
          values={values}
          setEmail={setEmail}
        />
      )}
      <div className="rowSection firstFragment">
        <div className="leftfragment">
          <div className="row">
            <h1>{product.name}</h1>
            <Rate rate={product.rate} />
          </div>
          <p>{product.description}</p>
          {product.content && product.content.length > 0 && (
            <>
              <h3>CONTENU : </h3>
              <ul>
                {product.content.map((item, index) => {
                  return <li key={index}>{item}</li>;
                })}
              </ul>
            </>
          )}

          <Form displayEmail={displayEmail} setDisplayEmail={setDisplayEmail}/>
          <div className="price">
            <button onClick={() => handleCheckOut()} className="buy">PASSER AU PAIEMENT</button>
            <Price price={product.price} coupon={product.coupon} />
          </div>
        </div>
        <div className="rightfragment">
          <img src={ebook} alt="MANIFESTE" className="product" />
        </div>
      </div>
      <CommentsSection id={product.id} rate={product.rate} />
    </div>
  );
};

export default Ebook;
