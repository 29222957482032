import { useEffect, useState } from "react";
import { handleFetchData, handlePostRequest } from "../../API/HandleRequests";
import { metadataRoute, modifyProductRoute } from "../../utils/APIRoutes";
import { InfinitySpin } from "react-loader-spinner";
import { SET_PRODUCT, SET_PRODUCT_ID } from "../../Reducers/adminReducer";
const ModifyProduct = ({ products, product, adminDispatch }) => {
  const handleChange = (event) => {
    adminDispatch({
      type: SET_PRODUCT,
      payload: { ...product, [event.target.name]: event.target.value },
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await handleFetchData(metadataRoute, { id: product.id });
      if (result.status === "ok") {
        adminDispatch({ type: SET_PRODUCT, payload: result.data.metadata });
      }
    };

    if (!product.loaded) {
      fetchData();
    }
  }, [product.id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await handlePostRequest(modifyProductRoute, product);
  };

  const handleAddLine = () => {
    const updatedContent = [...product.content, ""];
    adminDispatch({
      type: SET_PRODUCT,
      payload: { ...product, content: updatedContent },
    });
  };

  const handleDeleteLine = (index) => {
    const updatedContent = [...product.content];
    updatedContent.splice(index, 1);
    adminDispatch({
      type: SET_PRODUCT,
      payload: { ...product, content: updatedContent },
    });
  };

  const handleContentChange = (index, value) => {
    const updatedContent = [...product.content];
    updatedContent[index] = value;
    adminDispatch({
      type: SET_PRODUCT,
      payload: { ...product, content: updatedContent },
    });
  };

  return (
    <>
      {products.loaded && (
        <select
          name="id"
          id=""
          value={product.id}
          onChange={(e) =>
            adminDispatch({
              type: SET_PRODUCT_ID,
              payload: e.target.value,
            })
          }
        >
          {products.content.map((item, index) => {
            return (
              <option value={item.id} key={index}>
                {item.name}
              </option>
            );
          })}
        </select>
      )}

      {!product.loaded ? (
        <div className="loadercontainer">
          <InfinitySpin
            color="var(--main-color)"
            wrapperClassName="loadercontainer"
          />
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="fields">
            <div className="field">
              <label htmlFor="">Modifier la description</label>
              <textarea
                type="text"
                value={product.description}
                onChange={handleChange}
                name="description"
                placeholder="Si vous laissez ce champ vide, la description du produit sur stripe sera utilisée."
              />
            </div>

            <div className="field">
              <label htmlFor="">Modifier le contenu</label>
              {product.content.map((contentLine, index) => (
                <div key={index}>
                  <input
                    key={index}
                    type="text"
                    value={contentLine}
                    onChange={(e) => handleContentChange(index, e.target.value)}
                  />
                  <h3
                    className="borderbtn"
                    onClick={() => handleDeleteLine(index)}
                  >
                    Supprimer
                  </h3>
                </div>
              ))}
              <h3 className="borderbtn" onClick={handleAddLine}>
                Ajouter une ligne
              </h3>
            </div>

            <div className="field">
              <label htmlFor="">Coupon de réduction actif :</label>

              <input
                type="text"
                name="coupon"
                value={product.coupon}
                onChange={handleChange}
                placeholder={
                  !product.coupon && "Rentrez l'identifiant du coupon"
                }
              />
            </div>
          </div>

          <button type="sumbit">Mettre à jour le produit</button>
        </form>
      )}
    </>
  );
};

export default ModifyProduct;
