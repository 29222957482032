export const SET_INFOS = "SET_INFOS";
export const SET_MAIN_PRODUCT = "SET_MAIN_PRODUCT";
export const SET_COMMENTS = "SET_COMMENTS";
export const REMOVE_INFO = "REMOVE_INFO";
export const ADD_INFO = "ADD_INFO";

// Reducer
export const appReducer = (state, action) => {
  switch (action.type) {
    case SET_INFOS:
      return {
        ...state,
        topScreenLibs: {
          ...state.topScreenLibs,
          content: action.payload,
          loaded: true,
        },
      };
    case SET_MAIN_PRODUCT:
      return {
        ...state,
        main_product: {
          ...state.main_product,
          metadata: action.payload,
          loaded: true,
        },
      };
    case SET_COMMENTS:
      return {
        ...state,
        previewComments: {
          ...state.previewComments,
          content: action.payload,
          loaded: true,
        },
      };
    case ADD_INFO:
      return {
        ...state,
        topScreenLibs: {
          ...state.topScreenLibs,
          content: [action.payload, ...state.topScreenLibs.content],
        },
      };

    case REMOVE_INFO:
      return {
        ...state,
        topScreenLibs: {
          ...state.topScreenLibs,
          content: state.topScreenLibs.content.filter(
            (item) => item !== action.payload
          ),
        },
      };
    default:
      return state;
  }
};
