import { useEffect } from "react";
import { handleFetchData } from "../../../API/HandleRequests";
import { deleteRoute, getUnVisible } from "../../../utils/APIRoutes";
import Rate from "../../AvisComponents/Rate";
import {
  SET_UNVISIBLE,
  REMOVE_COMMENT_FROM_UNVISIBLES,
} from "../../../Reducers/adminReducer";
import { handlePostRequest } from "../../../API/HandleRequests";
import { InfinitySpin } from "react-loader-spinner";

const UnVisibles = ({ unvisibles, adminDispatch, handleToogle, product }) => {
  useEffect(() => {
    const fetchComments = async () => {
      const result = await handleFetchData(getUnVisible, {
        skip: unvisibles.comments.length,
        gap: 12,
        product: product,
      });
      if (result.status === "ok") {
        adminDispatch({ type: SET_UNVISIBLE, payload: result.data.comments });
      }
    };

    if (!unvisibles.loaded && product) {
      fetchComments();
    }
  }, [product]);

  const handleDelete = async (id) => {
    const result = await handlePostRequest(deleteRoute, { id: id });
    if (result === "ok") {
      adminDispatch({
        type: REMOVE_COMMENT_FROM_UNVISIBLES,
        payload: id,
      });
    }
  };

  return (
    <div className="comments-unvisibles">
      {!unvisibles.loaded ? (
        <div className="loadercontainer">
          <InfinitySpin
            color="var(--main-color)"
            wrapperClassName="loadercontainer"
          />
        </div>
      ) : (
        <>
          {" "}
          {unvisibles.comments.length == 0 && (
            <h3>Il n'y a aucun commentaire.</h3>
          )}
          {unvisibles.comments.map((comment, index) => {
            return (
              <div className="comment" key={"unvisible-" + index}>
                <div className="onHoverInputs">
                  <h4 onClick={() => handleToogle(comment._id, "unvisibles")}>
                    Rendre visible
                  </h4>
                  <h4 onClick={() => handleDelete(comment._id)}>Supprimer</h4>
                </div>
                {comment.rate && <Rate rate={comment.rate} />}
                <h3>{comment.title}</h3>
                <span>
                  {comment.username + " le " + formatDate(comment.createdAt)}
                </span>
                <p>{comment.content}</p>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export default UnVisibles;
