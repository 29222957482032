import { addProductRoute } from "../../utils/APIRoutes";
import { handlePostRequest } from "../../API/HandleRequests";
import { useState } from "react";
const NewProduct = () => {
  const [values, setValues] = useState({
    id: "",
    description: "",
    content: [],
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await handlePostRequest(addProductRoute, values);
    if (result === "ok") {
      setValues({
        id: "",
        description: "",
        content: [],
      });
    }
  };

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const handleAddLine = () => {
    setValues({
      ...values,
      content: [...values.content, ""],
    });
  };

  const handleDeleteLine = (index) => {
    const updatedContent = [...values.content];
    updatedContent.splice(index, 1);
    setValues({
      ...values,
      content: updatedContent,
    });
  };

  const handleContentChange = (index, value) => {
    const updatedContent = [...values.content];
    updatedContent[index] = value;
    setValues({
      ...values,
      content: updatedContent,
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="fields">
        <div className="field">
          <label htmlFor="">* Identifiant stripe du produit</label>
          <input
            type="text"
            value={values.id}
            onChange={handleChange}
            name="id"
          />
        </div>

        <div className="field">
          <label htmlFor="">Ajouter une description </label>
          <textarea
            type="text"
            value={values.description}
            onChange={handleChange}
            name="description"
            placeholder="Si vous laissez ce champ vide, la description du produit sur stripe sera utilisée."
          />
        </div>

        <div className="field ">
          <label htmlFor="">Ajouter des informations sur le contenu</label>
          {values.content.map((contentLine, index) => (
            <div key={index}>
              <input
                key={index}
                type="text"
                value={contentLine}
                onChange={(e) => handleContentChange(index, e.target.value)}
              />
              <h3 className="borderbtn" onClick={() => handleDeleteLine(index)}>
                Supprimer
              </h3>
            </div>
          ))}
          <h3 className="borderbtn" onClick={handleAddLine}>
            Ajouter une ligne
          </h3>
        </div>
      </div>

      <button type="sumbit">Ajouter le produit</button>
    </form>
  );
};

export default NewProduct;
