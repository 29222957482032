import "./forms.css";
import Back from "./Backbtn";
import { handlePostRequest } from "../../API/HandleRequests";
import { useState, useRef, useEffect } from "react";
import { registerRoute } from "../../utils/APIRoutes";
const RegisterForm = ({ setForm }) => {
  const [values, setValues] = useState({
    email: "",
    password: "",
    password2: "",
  });
  const handleSubmit = async (e) => {
    e.preventDefault();

    const result = await handlePostRequest(registerRoute, values);

    if (result === "ok") {
      setValues({
        email: "",
        password: "",
        password2: "",
      });
      setTimeout(() => {
        setForm("login");
      }, 1000);
    }
  };

  const progress = useRef(undefined);
  useEffect(() => {
    const passwordLength = values.password.length;
    progress.current.classList.remove("bad", "ok", "good");

    if (passwordLength > 1 && passwordLength < 8) {
      progress.current.classList.add("bad");
    } else if (passwordLength >= 8 && passwordLength < 12) {
      progress.current.classList.add("ok");
    } else if (passwordLength >= 12) {
      progress.current.classList.add("good");
    }
  }, [values.password]);

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  return (
    <div>
      <form
        action="
  "
        onSubmit={handleSubmit}
      >
        {" "}
        <Back txt={"Retour à l'accueil"} route={"/"} />
        <h1>
          FRANCK<span>.</span>RCRD
        </h1>
        <p>
          Vous avez déjà un compte ?{" "}
          <span
            onClick={() => {
              setForm("login");
            }}
          >
            Se connecter.
          </span>
        </p>
        <div className="fields">
          <div className="field">
            <label htmlFor="">EMAIL</label>
            <input
              type="text"
              placeholder="Entrez votre email"
              name="email"
              value={values.email}
              onChange={handleChange}
            />
          </div>
          <div className="field">
            <label htmlFor="">MOT DE PASSE</label>
            <input
              type="password"
              placeholder="Entrez votre mot de passe"
              name="password"
              value={values.password}
              onChange={handleChange}
            />
            <div className="strongpassword">
              <div className="progress" ref={progress}></div>
            </div>
          </div>
          <div className="field">
            <label htmlFor="">RETAPEZ VOTRE MOT DE PASSE</label>
            <input
              type="password"
              placeholder="Retapez ici"
              value={values.password2}
              name="password2"
              onChange={handleChange}
            />
          </div>
        </div>
        <button type="submit">CRÉER UN COMPTE</button>
      </form>
    </div>
  );
};

export default RegisterForm;
