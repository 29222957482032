import "./PopUp.css";
import { useEffect } from "react";
const PopUp = ({ setPopUp, request, values, setEmail }) => {
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      request();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  return (
    <div className="popUp">
      <div className="content">
        <span id="responsive" onClick={() => setPopUp(false)}>
          {" "}
          <ion-icon name="arrow-back-outline"></ion-icon>
          Annuler
        </span>

        <form action="" onSubmit={(e) => e.preventDefault()}>
          <h3>Vous n'êtes pas connecté.</h3>
          <div className="fields">
            <p htmlFor="">
              Vous ne semblez pas connecté, rentrez votre adresse mail de
              réception du produit. <br /> <br />
              Vous pourrez retrouver le produit dans votre espace client en vous
              connectant au compte correspondant ou en créant un compte avec
              cette même adresse.
            </p>{" "}
            <div className="field">
              <label htmlFor="">Votre adresse email de réception</label>
              <input
                type="text"
                placeholder="lucifer.morningstar@gmail.com"
                value={values.email}
                onChange={(e) => setEmail({ ...values, email: e.target.value })}
              />
            </div>
            <div className="field">
              {" "}
              <label htmlFor="">Retapez l'adresse</label>
              <input
                type="text"
                placeholder="lucifer.morningstar@gmail.com"
                value={values.email2}
                onChange={(e) =>
                  setEmail({ ...values, email2: e.target.value })
                }
              />
            </div>
            <h3 className="borderbtn" onClick={() => request()}>
              PASSER AU PAIEMENT →
            </h3>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PopUp;
