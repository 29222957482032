import ytb from "../../assets/ytb.svg";
import tiktok from "../../assets/tiktok.svg";

import twt from "../../assets/twt.svg";
import insta from "../../assets/insta.svg";
import "./medias.css";
const MediasComponent = () => {
  return (
    <div className="iconcontainer">
      <a href="https://www.instagram.com/escouade.omega/">
      <img src={insta} alt="instaico" />
      </a>

      <a href="https://www.tiktok.com/@escouade.omega">
      <img src={tiktok} alt="tiktokico" />
      </a>

      <a href="#">
      <img src={ytb} alt="youtubeico" />
      </a>

      <a href="#">
        <img src={twt} alt="twitterico" />
      </a>
    
    </div>
  );
};

export default MediasComponent;
