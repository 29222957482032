import axios from "axios";

import { toast } from "react-toastify";
import {
  tokenExpire,
  toastOptions,
  pendingOptions,
  updateToast,
} from "../Functions";
import { loginRoute, paymentRoute } from "../utils/APIRoutes";

function getAccessTokenFromCookie() {
  const cookies = document.cookie.split(";");
  for (const cookie of cookies) {
    const trimmedCookie = cookie.trim();
    if (trimmedCookie.startsWith("access-token=")) {
      const tokenValue = trimmedCookie.substring("access-token=".length);
      return tokenValue;
    }
  }
  return null; // Ajustez le comportement si le cookie n'est pas trouvé
}

// HANDLE GET REQUESTS :
export const handleFetchData = async (url, parameters, user) => {
  try {
    parameters.token = JSON.parse(
      localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)
    );

    const isLoggedIn = document.cookie.includes("logged_in=true");

    if (isLoggedIn) {
      parameters.token = getAccessTokenFromCookie();
    }
    const response = await Promise.race([
      axios.get(url, { params: parameters }),
      new Promise((_, reject) =>
        setTimeout(() => reject(new Error("Timeout")), 15000)
      ),
    ]);

    switch (response.data.status) {
      case "ok":
        if (response.data.info) {
          toast.info(response.data.info, toastOptions);
        }
        return { status: "ok", data: response.data };
      case "admin":
        toast.error(response.data.error, toastOptions);
        return { status: "error" };
      default:
        toast.error(response.data.error, toastOptions);
        return { status: "error" };
    }
  } catch (error) {
    if (error.response.data.status === "token") {
      tokenExpire();
    } else if (error.message === "Timeout") {
      toast.error("Une erreur s'est produite.", toastOptions);
    } else {
      toast.error("Une erreur s'est produite.", toastOptions);
    }

    return { status: "error" };
  }
};

// HANDLE POST REQUESTS :
export const handlePostRequest = async (url, parameters) => {
  parameters.token = JSON.parse(
    localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)
  );

  const isLoggedIn = document.cookie.includes("logged_in=true");

  if (isLoggedIn) {
    parameters.token = getAccessTokenFromCookie();
  }
  const id = toast.loading("Traitement de votre requête...", pendingOptions);

  try {
    const response = await axios.post(url, parameters);
    switch (response.data.status) {
      case "ok":
        if (response.data.message) {
          toast.update(id, updateToast("success", response.data.message));
        } else {
          toast.dismiss(id);
        }

        if (url === loginRoute) {
          return { status: "ok", token: response.data.token };
        }

        if (url === paymentRoute) {
          window.location.href = response.data.url;
        }

        return "ok";

      case "admin":
        toast.update(id, updateToast("error", response.data.error));
        return "error";

      default:
        toast.update(id, updateToast("error", response.data.error));
        return "error";
    }
  } catch (error) {
    if (error.response.data.status === "token") {
      toast.dismiss(id);

      tokenExpire();
    } else {
      toast.update(id, updateToast("error", error.response.data.error));
    }

    return { status: "error" };
  }
};
