import "./App.css";
import NavBar from "./components/navBar/NavBar";
import TopScreen from "./components/topScreen/TopScreen";
import Home from "./pages/Home";
import Footer from "./components/Footer/Footer";
import { Routes, Route, useLocation } from "react-router-dom";
import Contact from "./pages/Contact";
import { useState, useEffect, useRef, useReducer } from "react";
import LoadingPage from "./pages/LoadingPage";
import Ebook from "./pages/Ebook";
import { motion, AnimatePresence } from "framer-motion"; // Importer motion et AnimatePresence depuis framer-motion
import MediasComponent from "./components/Medias/MediaComponent";
import Logs from "./pages/LogsPage";
import { toast, ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserContext, UserDispatchContext } from "./UserContext";
import { userReducer, SET_LOG } from "./Reducers/userReducer";
import UserPage from "./pages/User";
import { toastOptions } from "./Functions";
import {
  SET_COMMENTS,
  SET_MAIN_PRODUCT,
  appReducer,
  SET_INFOS,
} from "./Reducers/appReducer";
import { AppContext, AppDispatchContext } from "./AppContext";
import { adminReducer } from "./Reducers/adminReducer";
import { AdminContext, AdminDispatchContext } from "./AdminContext";
import { commentsRoute, mainProductRoute, getInfos } from "./utils/APIRoutes";
import { handleFetchData } from "./API/HandleRequests";
import routeConfig from "./routeConfig";
import { Helmet } from "react-helmet";
import ChangePassword from "./pages/ChangePassword";
import NotFound from "./pages/NotFound";
import About from "./pages/About";
import Mentions from "./pages/Mentions";
function App() {
  const [user, dispatch] = useReducer(userReducer, {
    log: false,
    loaded: false,
  });

  useEffect(() => {
    const isLoggedIn = document.cookie.includes("logged_in=true");

    if (localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)) {
      dispatch({ type: SET_LOG, payload: true });
    } else if (isLoggedIn) {
      dispatch({ type: SET_LOG, payload: true });
    }
  }, []);

  const [appState, app_dispatch] = useReducer(appReducer, {
    previewComments: { content: [], loaded: false },
    topScreenLibs: { content: [], loaded: false },
    main_product: { metadata: {}, loaded: false },
  });

  useEffect(() => {
    const fetchData = async () => {
      const result = await handleFetchData(mainProductRoute, {});

      if (result.status === "ok") {
        app_dispatch({ type: SET_MAIN_PRODUCT, payload: result.data.metadata });
      }
    };

    if (!appState.main_product.loaded) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    const fetchComments = async () => {
      const result = await handleFetchData(commentsRoute, {
        skip: 0,
        gap: 8,
        product: appState.main_product.metadata.id,
        commentsPerPage : 8
      });
      if (result.status === "ok") {
        app_dispatch({ type: SET_COMMENTS, payload: result.data.comments });
      }
    };
    if (!appState.previewComments.loaded && appState.main_product.loaded) {
      fetchComments();
    }
  }, [appState.main_product.loaded]);

  const [adminState, admin_dispatch] = useReducer(adminReducer, {
    selected: 0,
    comments: {
      visibles: { comments: [], loaded: false },
      unvisibles: { comments: [], loaded: false },
    },

    product: {
      id: undefined,
      loaded: false,
      price: "",
      description: "",
      coupon: "",
      content: [],
    },
    products: { content: [], loaded: false },
  });

  const [loading, setLoading] = useState(true);
  const location = useLocation(); // Obtenir l'emplacement courant

  const prevLocationRef = useRef(location);
  const routes = ["/", "/ebook", "/contact", "/about"];

  const getAnimationProps = (location) => {
    const currentIndex = routes.indexOf(location.pathname);
    const prevIndex = routes.indexOf(prevLocationRef.current.pathname);

    if (currentIndex > prevIndex) {
      // La nouvelle route est à droite de l'emplacement précédent
      return {
        initial: { opacity: 0, x: 100 },
        animate: { opacity: 1, x: 0 },
        exit: { opacity: 0, x: -100 },
      };
    } else {
      // La nouvelle route est à gauche de l'emplacement précédent
      return {
        initial: { opacity: 0, x: -100 },
        animate: { opacity: 1, x: 0 },
        exit: { opacity: 0, x: 100 },
      };
    }
  };

  // revoir le loading pour attendre le chargement de la grosse image avant de loading false
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    // Fonction pour mettre à jour la progression et ajuster la translation
    const updateProgress = () => {
      const totalElements = 3; // Le nombre total d'éléments à charger (previewComments, imageGallery, videoPlayer)
      let loadedElements = 0;

      // Vérifier si les éléments sont chargés et mettre à jour le nombre d'éléments chargés
      if (appState.previewComments.loaded) {
        loadedElements++;
      }

      if (appState.topScreenLibs.loaded) {
        loadedElements++;
      }

      if (appState.main_product.loaded) {
        loadedElements++;
      }

      // Calculer la progression
      const newProgress = loadedElements * (100 / totalElements);
      // Mettre à jour la progression
      setProgress(newProgress);
    };

    // Appeler la fonction pour mettre à jour la progression lorsque le state de appState change
    updateProgress();
  }, [appState]);

  const loadingPage = useRef(undefined);
  useEffect(() => {
    if (progress == 100) {
      loadingPage.current.classList.toggle("out");
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [progress]);

  useEffect(() => {
    window.scrollTo(0, 0);
    prevLocationRef.current = location;
  }, [location]);

  const [isNavHidden, setIsNavHidden] = useState(false);

  const [lastScrollTime, setLastScrollTime] = useState(Date.now());

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (lastScrollY < currentScrollY && currentScrollY > 200) {
        setIsNavHidden(true);
      } else {
        setIsNavHidden(false);
      }

      lastScrollY = currentScrollY;
      setLastScrollTime(Date.now());
    };

    if (isSafari) {
      window.addEventListener("wheel", handleScroll);
    } else {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (isSafari) {
        window.removeEventListener("wheel", handleScroll);
      } else {
        window.removeEventListener("scroll", handleScroll);
      }
    };
  }, [isSafari]);

  // Utiliser un autre useEffect pour masquer la barre de navigation après 1 seconde d'inactivité
  useEffect(() => {
    const interval = setInterval(() => {
      // Vérifier si une seconde s'est écoulée depuis le dernier défilement
      if (Date.now() - lastScrollTime >= 1000) {
        if (window.scrollY > 200) {
          setIsNavHidden(true);
        }
      }
    }, 100); // Vérifier toutes les 100 millisecondes

    return () => {
      clearInterval(interval);
    };
  }, [lastScrollTime]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const successParam = queryParams.get("success");

    if (successParam === "true") {
      // Do something when success=true is present in the URL
      toast.success(
        "Paiement réussi, manifeste envoyé par mail.",
        toastOptions
      );
    }
  }, [location]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const successParam = queryParams.get("log");

    if (successParam === "true") {
      // Do something when success=true is present in the URL
      toast.success("Connexion réussie.", toastOptions);
    }
  }, [location]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await handleFetchData(getInfos, {});
      if (result.status === "ok") {
        app_dispatch({ type: SET_INFOS, payload: result.data.infos });
      }
    };

    if (!appState.topScreenLibs.loaded) {
      fetchData();
    }
  }, []);

  return (
    <>
      {routeConfig[location.pathname] && (
        <Helmet>
          <title>{routeConfig[location.pathname].title}</title>

          <meta
            name="keywords"
            content={routeConfig[location.pathname].keywords}
          />
          <meta
            name="description"
            content={routeConfig[location.pathname].description}
          />
        </Helmet>
      )}
      <AppContext.Provider value={appState}>
        <AppDispatchContext.Provider value={app_dispatch}>
          <UserContext.Provider value={user}>
            <UserDispatchContext.Provider value={dispatch}>
              {loading && (
                <LoadingPage progress={progress} loadingPage={loadingPage} />
              )}
              <ToastContainer
                progressClassName="toastProgress"
                transition={Slide}
              />

              <div
                className={
                  location.pathname.startsWith("/logs") ||
                  location.pathname.startsWith("/change")
                    ? ""
                    : "App"
                }
              >
                {!location.pathname.startsWith("/logs") &&
                  !location.pathname.startsWith("/change") && (
                    <>
                      <MediasComponent />{" "}
                      <header>
                        <NavBar isNavHidden={isNavHidden} />
                        <TopScreen />
                      </header>
                    </>
                  )}

                <AnimatePresence mode="wait">
                  {" "}
                  {/* Utiliser AnimatePresence autour des éléments de routage */}
                  <Routes>
                    <Route
                      path="/"
                      element={
                        // Utiliser motion.div comme conteneur d'animation pour chaque route
                        <motion.div
                          key="/"
                          {...getAnimationProps(location)}
                          transition={{ duration: 0.5 }}
                        >
                          <Home />
                        </motion.div>
                      }
                    />
                    <Route
                      path="/contact"
                      element={
                        // Utiliser motion.div comme conteneur d'animation pour chaque route
                        <motion.div
                          key="/contact"
                          {...getAnimationProps(location)}
                          transition={{ duration: 0.5 }}
                        >
                          <Contact />
                        </motion.div>
                      }
                    />

                    <Route
                      path="/ebook"
                      element={
                        // Utiliser motion.div comme conteneur d'animation pour chaque route
                        <motion.div
                          key="/ebook"
                          {...getAnimationProps(location)}
                          transition={{ duration: 0.5 }}
                        >
                          <Ebook product={appState.main_product.metadata} />
                        </motion.div>
                      }
                    />

                    <Route
                      path="/about"
                      element={
                        // Utiliser motion.div comme conteneur d'animation pour chaque route
                        <motion.div
                          key="/about"
                          {...{
                            initial: { opacity: 0, y: 100 },
                            animate: { opacity: 1, y: 0 },
                            exit: { opacity: 0, y: 100 },
                          }}
                          transition={{ duration: 0.5 }}
                        >
                          <About />
                        </motion.div>
                      }
                    />

                    <Route
                      path="/mentions"
                      element={
                        // Utiliser motion.div comme conteneur d'animation pour chaque route
                        <motion.div
                          key="/mentions"
                          {...getAnimationProps(location)}
                          transition={{ duration: 0.5 }}
                        >
                          <Mentions />
                        </motion.div>
                      }
                    />
                    {!user.log ? (
                      <>
                        <Route
                          path="/logs"
                          element={
                            // Utiliser motion.div comme conteneur d'animation pour chaque route
                            <Logs />
                          }
                        />
                        <Route
                          path="/change/:token"
                          element={<ChangePassword />}
                        />
                      </>
                    ) : (
                      <Route
                        path="/user"
                        element={
                          <AdminContext.Provider value={adminState}>
                            <AdminDispatchContext.Provider
                              value={admin_dispatch}
                            >
                              <UserPage />
                            </AdminDispatchContext.Provider>
                          </AdminContext.Provider>
                        }
                      />
                    )}

                    <Route status={404} element={<NotFound></NotFound>}></Route>
                    <Route path="*" element={<NotFound></NotFound>}></Route>
                  </Routes>
                </AnimatePresence>
              </div>
              {!location.pathname.startsWith("/logs") &&
                !location.pathname.startsWith("/change") && <Footer />}
            </UserDispatchContext.Provider>
          </UserContext.Provider>
        </AppDispatchContext.Provider>
      </AppContext.Provider>
    </>
  );
}

export default App;
