

import React, { useState } from 'react'
import { handlePostRequest } from '../../API/HandleRequests'
import { freeProductRoute } from '../../utils/APIRoutes'
import { toast } from 'react-toastify'
import { toastOptions } from '../../Functions'
import { motion,AnimatePresence } from "framer-motion"; // Importer motion et AnimatePresence depuis framer-motion

const Form = ({displayEmail, setDisplayEmail, cancel = true,  placeholder ="Entrez votre adresse mail pour recevoir l'extrait.."}) => {

 const [email, setEmail] = useState("")
const [loading, setLoading] = useState(false)
  const handleSubmit = async(e) => {
    e.preventDefault()
    if(loading){return}
    if(email.length <= 4){
      toast.error("Adresse invalide.", toastOptions)

      return;
    }
    if(localStorage.getItem("email") === "true"){
      toast.info("Vous avez déjà reçu l'extrait...", toastOptions)
      setEmail("")
      setDisplayEmail(false)
      return ;
    }

    setLoading(true)
    const result =  await handlePostRequest(freeProductRoute, {email});

    if (result === "ok") {
      setEmail("")
      setDisplayEmail(false) 
      localStorage.setItem("email", "true")
    }
    setLoading(false)
} 

  

  return (
    <>
    {cancel &&   <h3 className="borderbtn" onClick={()=> setDisplayEmail(!displayEmail)} >
          {!displayEmail ? "Télécharger l'extrait gratuit →" : "Annuler"}
        </h3>}
   
    
  
    <AnimatePresence>

    {displayEmail &&  <motion.div
      
      initial={{ opacity: 0, height: 0}}
      animate={{ opacity:  1 , height:"fit-content" }}
      exit={{ opacity: 0, height: 0 }}  // This is the reverse of your 'animate'
      transition={{ duration: 0.2, ease: "easeOut" }}
      onAnimationComplete={() => {
          if (!displayEmail) {
              setDisplayEmail(false)
          }
      }}>
      <form onSubmit={handleSubmit} 
      className='free' 

          >
                <input className="free-input" value={email} onChange={(e) => setEmail(e.target.value)} placeholder={placeholder} />
                <button type="submit" style={{display:"block",marginLeft:"auto"}} disabled={loading}>Confirmer</button>
          </form>
      </motion.div>
         }
  </AnimatePresence>

  </>
  )
}

export default Form