import "./price.css";

const Price = ({ price, coupon }) => {
  // Calculate the discounted price
  const discountedPrice = price - price * (coupon / 100);

  // Round the discounted price to two decimal places
  const roundedPrice = discountedPrice.toFixed(2);

  return (
    <div className={coupon ? "pricebloc" : ""}>
      {coupon ? (
        <>
          <h1>{price}€ </h1>
          <div>
            <h1>{roundedPrice}€</h1>
            <div>
              <ion-icon name="pricetag"></ion-icon>
              <span> -{Math.floor(coupon)}%</span>
            </div>
          </div>
        </>
      ) : (
        <div style={{display:"flex", flexDirection:"column", position:"relative"}}>  
        <div className="row2">
        <h1 style={{margin:0}}>{price}€</h1>

        <ion-icon name="trending-up-outline" className="shine"></ion-icon> 

          </div>    



      <span style={{ width:"100%", color : "var(--text-color)", opacity:0.8 , textShadow:"1px 1px 2px rgba(0, 0, 0, 0.25)"}} className="descP">Le prix augmente tous les 100 achats</span>
     
     
        </div>
     
        
      )}
    </div>
  );
};

export default Price;
