import { Link, useMatch, useResolvedPath, useNavigate } from "react-router-dom";
import "./nav.css";
import NavComponent from "../Responsive/NavComponent";
import { useRef, useContext, useState } from "react";
import { UserContext } from "../../UserContext";

const NavBar = ({ isNavHidden }) => {
  const navigate = useNavigate();
  const ul = useRef(undefined);

  const user = useContext(UserContext);
  const [inHover, setInHover] = useState(false)
  
  function handleListItemHover() {
    setInHover(true)
  }
  
  function handleListMouseLeave() {
    setInHover(false)

  }
  return (

      <nav className={`nav ${isNavHidden ? "nav--hidden" : ""}`}>
        <h1 onClick={() => navigate("/")}>
          FRANCK<span>.</span>RCRD
        </h1>
        <ul ref={ul}>
          <CustomLink to="/" handleListItemHover={handleListItemHover} handleListMouseLeave={handleListMouseLeave} inHover={inHover}>ACCUEIL</CustomLink>
          <CustomLink to="/ebook" handleListItemHover={handleListItemHover} handleListMouseLeave={handleListMouseLeave}  inHover={inHover}>E-BOOK</CustomLink>
          <CustomLink to="/about" handleListItemHover={handleListItemHover} handleListMouseLeave={handleListMouseLeave}   inHover={inHover}>À PROPOS DE MOI</CustomLink>
          <CustomLink to="/contact" handleListItemHover={handleListItemHover} handleListMouseLeave={handleListMouseLeave}   inHover={inHover}>CONTACT</CustomLink>
        </ul>

<div style={{display:"flex", gap:"20px", alignItems:"center"}}>
<CustomIco to={user.log ? "/user" : "/logs"}>
          <svg
            width="33"
            height="33"
            viewBox="0 0 33 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.5 18.5625C21.624 18.5625 25.7812 14.4053 25.7812 9.28125C25.7812 4.15723 21.624 0 16.5 0C11.376 0 7.21875 4.15723 7.21875 9.28125C7.21875 14.4053 11.376 18.5625 16.5 18.5625ZM24.75 20.625H21.1986C19.7678 21.2824 18.1758 21.6562 16.5 21.6562C14.8242 21.6562 13.2387 21.2824 11.8014 20.625H8.25C3.69316 20.625 0 24.3182 0 28.875V29.9062C0 31.6143 1.38574 33 3.09375 33H29.9062C31.6143 33 33 31.6143 33 29.9062V28.875C33 24.3182 29.3068 20.625 24.75 20.625Z"
              fill="white"
              fill-opacity="0.62"
            />
          </svg>
        </CustomIco>

        <NavComponent ul={ul} />
</div>
      
      </nav>

  );
};

function CustomLink({ to, handleListItemHover,handleListMouseLeave,inHover, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  return (
    <li className={`${isActive ? "selected " : " "} ${inHover ? " hover" : " "}`}

    onMouseEnter={handleListItemHover} onMouseLeave={handleListMouseLeave}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
}

function CustomIco({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  return (
    <div className={isActive ? "user-nav selected" : "user-nav"}>
      <Link to={to}>{children}</Link>
    </div>
  );
}

export default NavBar;
