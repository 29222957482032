import { useState } from "react";
import "./form.css";
import { handlePostRequest } from "../../../API/HandleRequests";
import { postCommentRoute } from "../../../utils/APIRoutes";
import Rate from "../../AvisComponents/Rate";

const NewComment = ({ id }) => {
  const [values, setValues] = useState({
    product: id,
    username: "",
    user_email: "",
    title: "",
    content: "",
    rate: 0,
  });

  const handleSubmit = async () => {
    const result = await handlePostRequest(postCommentRoute, values);

    if (result === "ok") {
      setValues({
        product: id,
        username: "",
        user_email: "",
        title: "",
        content: "",
        rate: 0,
      });
    }
  };

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const handleRate = (rate) => {
    setValues({ ...values, rate: rate + 1 });
  };
  return (
    <form action="">
      <div className="fields">
        <div className="field">
          <label htmlFor="">Nom</label>
          <input
            type="text"
            name="username"
            placeholder="Lucifer Morningstar"
            value={values.username}
            onChange={handleChange}
          />
        </div>
        <div className="field">
          <label htmlFor="">Adresse mail</label>
          <input
            type="mail"
            name="user_email"
            placeholder="lucifer.morningstar@gmail.com"
            value={values.user_email}
            onChange={handleChange}
          />
        </div>

        <div className="field rating">
          <label htmlFor="">Note</label>
          <Rate rate={values.rate} handleChange={handleRate} />
        </div>
        <div className="field">
          <label htmlFor="">Titre de l'avis</label>
          <input
            type="text"
            name="title"
            placeholder="Vraiment super !"
            value={values.title}
            onChange={handleChange}
          />
        </div>
        <div className="field">
          <label htmlFor="">Votre avis</label>
          <textarea
            type="text"
            name="content"
            placeholder="Rédigez votre avis ici..."
            value={values.content}
            onChange={handleChange}
          />
        </div>
      </div>
      <h3 className="borderbtn" onClick={() => handleSubmit()}>
        Envoyer →
      </h3>
    </form>
  );
};

export default NewComment;
