import { useEffect, useState } from "react";
import franck from "../assets/franck.webp";
import franck2 from "../assets/franck2.webp";
import franck1 from "../assets/franck1.webp";

import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";

const About = () => {
  const navigate = useNavigate();

  const [progress, setProgress] = useState(0);
  const [ref1, inView1] = useInView({
    rootMargin: `${-35}% 0px`, // Définir la marge d'intersection
  }); // Hook pour observer le premier .rowSection
  const [ref2, inView2] = useInView(); // Hook pour observer le deuxième .rowSection

  const [state, setState] = useState({ img1: true, img2: true });
  useEffect(() => {
    // Mettez à jour la valeur de progress en fonction des éléments dans la vue
    if (inView2) {
      setProgress(100);
    } else if (inView1) {
      setProgress(66);
    } else {
      setProgress(33);
    }
  }, [inView1, inView2]);

  return (
    <div className="Home fragment about">
      <style>
        {`
          :root {
            --about: ${progress}%;
         
          }
        `}
      </style>

      <h1>FRANCK N.C. RICHARD</h1>

      <div className="ligne">
        <div></div>
      </div>
      <div className="rowSection">
        <div className="leftfragment">
          <div className="text-bloc">
            <h1>QUI SUIS-JE ?</h1>
            <h3>Sportif théoricien, Mathématicien</h3>
            <p>
            En quête de défis autant sur le terrain que dans les méandres de la théorie scientifique, je serai votre guide vers l'optimisation personnelle. Ingénieur mathématicien et passionné de sport, mon parcours se forge à l'intersection de la théorie et de l'action.
            </p>
            <p>
            Je vous propose une approche unique où chaque conseil prodigué est une fusion entre ma compréhension approfondie de la biomécanique et mes cinq années d'expérience en musculation, complémentés par une longue pratique d'autres sports divers.
            </p>
          </div>
        </div>
        <div className="rightfragment">
          <div className="imagecontainer">
            <img
              src={franck}
              alt="FRANCK N.C. RICHARD"
              onLoad={() => setState({ ...state, img1: true })}
            />
            <div className="support">
              <div className="ligne"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="rowSection row2" ref={ref1}>
        <div className="leftfragment">
          <div className="imagecontainer">
            <img
              src={franck2}
              alt="FRANCK N.C. RICHARD"
              onLoad={() => setState({ ...state, img2: true })}
            />
            <div className="support">
              <div className="ligne"></div>
            </div>
          </div>
        </div>
        <div className="rightfragment text-bloc2">
          <div className="text-bloc ">
            <h1>MON PARCOURS</h1>
            <h3>Étudiant, entrepreneur</h3>
            <p>
            Touche-à-tout multitâche, il m'aura fallu des années avant d'enfin savoir sur quoi je voulais me concentrer. Étudiant ingénieur en mathématiques appliquées, écrivain à mes heures perdues, pratiquant de sports depuis aussi loin que je me souvienne, passionné de sciences, j'ai enfin trouvé ma voie en puisant dans chaque chose qui me définissait jusque-là.

﻿
            </p>
            <p>
            C'est comme ça que cet e-book et ce site sont nés : un désir de grandeur couplé à une profonde envie d'enseigner et partager mes connaissances amassées au cours de mes aventures.
            </p>
          </div>
        </div>
      </div>

      <div className="centered-bloc" ref={ref2} style={{paddingBottom:"45px"}}>
        <div className="objectif">
          <h1>MON OBJECTIF</h1>
        </div>
        <p>
        Ma quête n'est autre qu'aider tous ceux qui désirent s'améliorer, que ce soit en les poussant à faire le premier pas vers le développement personnel, ou en les aidant à passer à la vitesse supérieure.
        </p>
        <button onClick={() => navigate("/ebook")} className="buy" style={{margin:"auto", display:"block"}}>
              VOIR MON E-BOOK
           </button>
      </div>
    </div>
  );
};

export default About;
