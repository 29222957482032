import { useEffect } from "react";
import { handleFetchData } from "../../../API/HandleRequests";
import { getVisible } from "../../../utils/APIRoutes";
import Rate from "../../AvisComponents/Rate";
import { SET_VISIBLES } from "../../../Reducers/adminReducer";
import { InfinitySpin } from "react-loader-spinner";

const Visibles = ({ visibles, adminDispatch, handleToogle, product }) => {
  useEffect(() => {
    const fetchComments = async () => {
      const result = await handleFetchData(getVisible, {
        skip: visibles.comments.length,
        gap: 12,
        product: product,
      });
      if (result.status === "ok") {
        adminDispatch({ type: SET_VISIBLES, payload: result.data.comments });
      }
    };

    if (!visibles.loaded && product) {
      fetchComments();
    }
  }, [product]);

  return (
    <div className="comments-visibles">
      {!visibles.loaded ? (
        <div className="loadercontainer">
          <InfinitySpin
            color="var(--main-color)"
            wrapperClassName="loadercontainer"
          />
        </div>
      ) : (
        <>
          {" "}
          {visibles.comments.length == 0 && (
            <h3>Pas de commentaires visibles.</h3>
          )}
          {visibles.comments.map((comment, index) => {
            return (
              <div className="comment" key={"visible-" + index}>
                <div className="onHoverInputs">
                  <div onClick={() => handleToogle(comment._id, "visible")}>
                    Rendre invisible
                  </div>
                </div>
                {comment.rate && <Rate rate={comment.rate} />}
                <h3>{comment.title}</h3>
                <span>
                  {comment.username + " le " + formatDate(comment.createdAt)}
                </span>
                <p>{comment.content}</p>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export default Visibles;
