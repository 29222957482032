import { useNavigate } from "react-router-dom";

const Back = ({ txt, route }) => {
  const navigate = useNavigate();

  return (
    <span id="responsive" onClick={() => navigate(route)}>
      {" "}
      <ion-icon name="arrow-back-outline"></ion-icon>
      {txt}
    </span>
  );
};
export default Back;
