import "./extrait.css";
import { Document, Page, pdfjs } from "react-pdf";

import { useEffect, useRef, useState } from "react";
import doc from "../../../assets/Manifeste_extrait.pdf";
import Price from "../../Price/Price";
import useResizeObserver from 'use-resize-observer'

import Form from "../Form";
import { AnimatePresence, motion } from "framer-motion";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const ExtraitBloc = ({ metadata, navigate, reference }) => {
  const [numPages, setNumPages] = useState(1);
  const { ref, width = 1, height = 1 } = useResizeObserver();
  const pdfContainerRef = useRef(null);

  const [displayEmail, setDisplayEmail] = useState(false)
  const [displayEmail2, setDisplayEmail2] = useState(false)


const [showMessage, setShowMessage] = useState(false)
  
function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }


 const handleScroll = () => {
    if (pdfContainerRef.current) {
      const scrollPosition = pdfContainerRef.current.scrollTop;
      const totalHeight = pdfContainerRef.current.scrollHeight;
      const containerHeight = pdfContainerRef.current.clientHeight;

      if (scrollPosition > (totalHeight - containerHeight) * 0.75) {
        setShowMessage(true);
      }else{
        setShowMessage(false);

      }
    }
  };

  useEffect(() => {
    const pdfContainer = pdfContainerRef.current;
    if (pdfContainer) {
      pdfContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (pdfContainer) {
        pdfContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <div className="rowSection extraitbloc" ref={reference}>
      <div className="leftfragment">
        <h1>EXTRAIT GRATUIT</h1>
        <p>
          Voici à votre disposition quelques pages de l'e-book. Convaincu ?
        </p>
       


      <Form setDisplayEmail={setDisplayEmail} displayEmail={displayEmail}/>
   
        
        <div className="price">
          <button onClick={() => navigate("/ebook")} className="buy">OBTENIR MON E-BOOK</button>
          <Price price={metadata.price} coupon={metadata.coupon} />
        </div>
      </div>
      <div className="rightfragment" style={{ width:"clamp(350px, 40vw, 574px)" }}>
      
        <div className="extraitcontainer" ref={pdfContainerRef}  style={{position:"relative"}}>
         
        <AnimatePresence>
           {showMessage && (
                <motion.div className="disclaimer-free"
                initial={{ opacity: 0, bottom:-100}}
                animate={{ opacity:  1 , bottom : 0}}
                exit={{ opacity: 0,bottom: - 100 }}  // This is the reverse of your 'animate'
                transition={{ duration: 0.2, ease: "easeOut" }}
                onAnimationComplete={() => {
                    if (!showMessage) {
                        setShowMessage(false)
                    }
                }}
                >
                <div>
                  <div className="blur">

                  </div>
                  <p>Rentrez votre adresse mail pour recevoir l'extrait gratuit.</p>
                      <Form setDisplayEmail={setDisplayEmail2} displayEmail={true} cancel={false} placeholder="Rentrez l'adresse ici..."/>
                </div>
                </motion.div>
           )}

          </AnimatePresence>
      
      
          <div className={`pdf-container ${showMessage ? "blur" : ""}`} ref={ref}>
           
            <Document
              file={doc}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={console.error}
            >
               {[...Array(numPages)].map((_, index) => (
          <Page
            key={index}
            pageNumber={index + 1}
            width={width || undefined}
            height={height || undefined}
            renderAnnotationLayer={false}
            renderTextLayer={false}
          />
        ))}
              
            </Document>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExtraitBloc;
