const Mentions = () => {
  return (
    <div className="mentions">
      <h1>Mentions légales et politique de confidentialité</h1>
      <p>
        L'entreprise individuelle FRANCK.RCRD, soucieuse des droits des
        individus, notamment au regard des traitements automatisés et dans une
        volonté de transparence avec ses clients, a mis en place une politique
        reprenant l’ensemble de ces traitements, des finalités poursuivies par
        ces derniers ainsi que des moyens d’actions à la disposition des
        individus afin qu’ils puissent au mieux exercer leurs droits. Pour toute
        information complémentaire sur la protection des données personnelles,
        nous vous invitons à consulter le site :{" "}
        <a href="https://www.cnil.fr/">https://www.cnil.fr/</a>.
      </p>

      <p>
        La poursuite de la navigation sur ce site vaut acceptation sans réserve
        des dispositions et conditions d'utilisation qui suivent. La version
        actuellement en ligne de ces conditions d'utilisation est la seule
        opposable pendant toute la durée d'utilisation du site et jusqu'à ce
        qu'une nouvelle version la remplace.
      </p>

      <h1>Article 1 - Mentions légales</h1>
      <p>
        <strong>1.1 Site (ci-après « le site ») :</strong>
        <br />
        FRANCK.RCRD
      </p>

      <p>
        <strong>1.2 Éditeur (ci-après « l'éditeur ») :</strong>
        <br />
        L'entreprise indivuduelle FRANCK.RCRD
        <br />
        Adresse mail : franck.richard.fr@live.fr
      </p>

      <p>
        <strong>1.3 Hébergeur (ci-après « l'hébergeur ») :</strong>
        <br />
        FRANCK.RCRD est hébergé par Heroku, dont le siège social est situé à 650
        7th Street, San Francisco, CA 94103, États-Unis.
      </p>

      <h1>Article 2 - Accès au site</h1>
      <p>
        L'accès au site et son utilisation sont réservés à un usage strictement
        personnel. Vous vous engagez à ne pas utiliser ce site et les
        informations ou données qui y figurent à des fins commerciales,
        politiques, publicitaires et pour toute forme de sollicitation
        commerciale et notamment l'envoi de courriers électroniques non
        sollicités.
      </p>

      <h1>Article 3 - Contenu du site</h1>
      <p>
        Toutes les marques, photographies, textes, commentaires, illustrations,
        images animées ou non, séquences vidéo, sons, ainsi que toutes les
        applications informatiques qui pourraient être utilisées pour faire
        fonctionner ce site et plus généralement tous les éléments reproduits ou
        utilisés sur le site sont protégés par les lois en vigueur au titre de
        la propriété intellectuelle. <br /> <br /> Ils sont la propriété pleine
        et entière de l'éditeur ou de ses partenaires. Toute reproduction,
        représentation, utilisation ou adaptation, sous quelque forme que ce
        soit, de tout ou partie de ces éléments, y compris les applications
        informatiques, sans l'accord préalable et écrit de l'éditeur, sont
        strictement interdites. <br />
        Le fait pour l'éditeur de ne pas engager de procédure dès la prise de
        connaissance de ces utilisations non autorisées ne vaut pas acceptation
        desdites utilisations et renonciation aux poursuites.
      </p>

      <h1>Article 4 - Gestion du site</h1>
      <p>
        Pour la bonne gestion du site, l'éditeur pourra à tout moment : <br /> -
        suspendre, interrompre ou limiter l'accès à tout ou partie du site,
        réserver l'accès au site, ou à certaines parties du site, à une
        catégorie déterminée d'internautes ; <br />- supprimer toute information
        pouvant en perturber le fonctionnement ou entrant en contravention avec
        les lois nationales ou internationales ; <br />- suspendre le site afin
        de procéder à des mises à jour.
      </p>

      <h1>Article 5 - Responsabilités</h1>
      <p>
        La responsabilité de l'éditeur ne peut être engagée en cas de
        défaillance, panne, difficulté ou interruption de fonctionnement,
        empêchant l'accès au site ou à une de ses fonctionnalités. Le matériel
        de connexion au site que vous utilisez est sous votre entière
        responsabilité. Vous devez prendre toutes les mesures appropriées pour
        protéger votre matériel et vos propres données notamment d'attaques
        virales par Internet. Vous êtes par ailleurs seul responsable des sites
        et données que vous consultez. <br /> <br />
        L'éditeur ne pourra être tenu responsable en cas de poursuites
        judiciaires à votre encontre : <br />- du fait de l'usage du site ou de
        tout service accessible via Internet ; <br />- du fait du non-respect
        par vous des présentes conditions générales. <br /> <br />
        L'éditeur n'est pas responsable des dommages causés à vous-même, à des
        tiers et/ou à votre équipement du fait de votre connexion ou de votre
        utilisation du site et vous renoncez à toute action contre lui de ce
        fait. Si l'éditeur venait à faire l'objet d'une procédure amiable ou
        judiciaire en raison de votre utilisation du site, il pourra se
        retourner contre vous pour obtenir l'indemnisation de tous les
        préjudices, sommes, condamnations et frais qui pourraient découler de
        cette procédure.
      </p>

      <h1>Article 6 - Liens hypertextes</h1>
      <p>
        La mise en place par les utilisateurs de tous liens hypertextes vers
        tout ou partie du site est autorisée par l'éditeur. Tout lien devra être
        retiré sur simple demande de l'éditeur. Toute information accessible via
        un lien vers d'autres sites n'est pas publiée par l'éditeur. L'éditeur
        ne dispose d'aucun droit sur le contenu présent dans ledit lien.
      </p>

      <h1>Article 7 - Collecte et protection des données</h1>
      <p>
        Vos données sont collectées par l'entreprise individuelle FRANCK.RCRD.
        Une donnée à caractère personnel désigne toute information concernant
        une personne physique identifiée ou identifiable (personne concernée) ;
        est réputée identifiable une personne qui peut être identifiée,
        directement ou indirectement, notamment par référence à un nom, un
        numéro d'identification ou à un ou plusieurs éléments spécifiques,
        propres à son identité physique, physiologique, génétique, psychique,
        économique, culturelle ou sociale. Les informations personnelles pouvant
        être recueillies sur le site sont principalement utilisées par l'éditeur
        pour la gestion des relations avec vous, et le cas échéant pour le
        traitement de vos commandes.
      </p>

      <h1>Article 15 - Loi applicable</h1>
      <p>
        Les présentes conditions d'utilisation du site sont régies par la loi
        française et soumises à la compétence des tribunaux du siège social de
        l'éditeur, sous réserve d'une attribution de compétence spécifique
        découlant d'un texte de loi ou réglementaire particulier.
      </p>

      <h1>Article 16 - Contactez-nous</h1>
      <p>
        Pour toute question, information sur les produits présentés sur le site,
        ou concernant le site lui-même, vous pouvez laisser un message à
        l'adresse suivante :{" "}
        <a href="mailto:franck.richard.fr@live.fr">franck.richard.fr@live.fr</a>
        .
      </p>
    </div>
  );
};

export default Mentions;
