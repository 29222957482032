import { useContext, useEffect } from "react";

import { UserContext, UserDispatchContext } from "../UserContext";
import { LOG_OUT } from "../Reducers/userReducer";
import { useNavigate } from "react-router-dom";
import { toast, Slide } from "react-toastify";
import { toastOptions } from "../Functions";
import ChangePassword from "../components/UserComponents/ChangePassword";
import Orders from "../components/UserComponents/Orders";
import { handleFetchData } from "../API/HandleRequests";
import { userRoute } from "../utils/APIRoutes";
import { SET_USER } from "../Reducers/userReducer";
import { InfinitySpin } from "react-loader-spinner";
import AdminSection from "../components/AdminComponents/AdminSection";

const UserPage = () => {
  const user = useContext(UserContext);

  const dispatch = useContext(UserDispatchContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const result = await handleFetchData(userRoute, {}, user);
      if (result.status === "ok") {
        let user = result.data.user;
        user.products = result.data.products;
        dispatch({ type: SET_USER, payload: user });
      }
    };

    if (!user.loaded) {
      fetchData();
    }
  }, []);

  const handleDisconnect = () => {
    localStorage.removeItem(process.env.REACT_APP_LOCALHOST_KEY);
    // Supprimer les cookies en définissant la date d'expiration à une date passée
    const pastDate = new Date(0).toUTCString();
    document.cookie = `access-token=; expires=${pastDate}; path=/;`;
    document.cookie = `refresh-token=; expires=${pastDate}; path=/;`;
    document.cookie = `logged_in=; expires=${pastDate}; path=/;`;

    dispatch({ type: LOG_OUT });
    toast.success("Déconnexion réussie !", toastOptions);
    navigate("/");
  };

  return (
    <div className="contact fragment user">
      {user.loaded ? (
        <>
          <div className="row">
            <h1>{user.admin ? "ESPACE ADMINISTRATEUR" : "VOTRE COMPTE"}</h1>
            <div>
              <h4
                onClick={() => navigate("/contact")}
                style={{ cursor: "pointer" }}
              >
                SIGNALER UN PROBLÈME ?
              </h4>
              <h3 onClick={() => handleDisconnect()} className="borderbtn">
                <ion-icon name="log-out-outline"></ion-icon> Déconnexion
              </h3>
            </div>
          </div>
          {user.admin ? (
            <AdminSection />
          ) : (
            <>
              <ChangePassword user={user} />
              <Orders products={user.products} usr={user} />
            </>
          )}
        </>
      ) : (
        <div className="loadercontainer">
          <InfinitySpin
            color="var(--main-color)"
            wrapperClassName="loadercontainer"
          />
        </div>
      )}
    </div>
  );
};

export default UserPage;
