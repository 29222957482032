import "./Footer.css";
import payments from "../../assets/payments.png";
import MediasComponent from "../Medias/MediaComponent";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <footer>
      <div className="footercontent">
        <div className="footersocials">
          <h1>
            FRANCK<span>.</span>RCRD
          </h1>
          <MediasComponent />
        </div>

        <div>
          <h3>A PROPOS</h3>
          <ul>
            <li>
              <Link to="/">ACCUEIL</Link>
            </li>
            <li>
              <Link to="/mentions">MENTIONS LÉGALES</Link>
            </li>{" "}
            <li>
              <Link to="/contact">CONTACT</Link>
            </li>
          </ul>
        </div>

        <div>
          <h3>PRODUITS</h3>
          <ul>
            <li>
              <Link to="/ebook">E-BOOK</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-infos">
        <h5>© 2023 FRANCK.RCRD |Tous droits réservés</h5>
        <img src={payments} alt="payments" />
      </div>
    </footer>
  );
};

export default Footer;
