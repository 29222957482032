import Back from "./Backbtn";
import "./forms.css";
import { handlePostRequest } from "../../API/HandleRequests";
import { useState, useContext } from "react";
import { loginRoute } from "../../utils/APIRoutes";
import { UserDispatchContext } from "../../UserContext";
import { SET_LOG } from "../../Reducers/userReducer";
import GoogleButton from "./GoogleLogin/GoogleButton";

const LoginForm = ({ setForm, navigate }) => {
  const dispatch = useContext(UserDispatchContext);

  const [values, setValues] = useState({
    email: "",
    password: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await handlePostRequest(loginRoute, values);
    if (result.status === "ok") {
      localStorage.setItem(
        process.env.REACT_APP_LOCALHOST_KEY,
        JSON.stringify(result.token)
      );
      setValues({
        email: "",
        password: "",
      });

      setTimeout(() => {
        dispatch({ type: SET_LOG, payload: true });
        navigate("/");
      }, 700);
    }
  };

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  return (
    <div>
      <form action="" onSubmit={handleSubmit}>
        <Back txt={"Retour à l'accueil"} route={"/"} />
        <h1>
          FRANCK<span>.</span>RCRD
        </h1>
        <p>
          Pas encore de compte ?{" "}
          <span
            onClick={() => {
              setForm("register");
            }}
          >
            Créer un compte.
          </span>
        </p>
        <div className="fields">
          <div className="field">
            <label htmlFor="">EMAIL</label>
            <input
              type="text"
              name="email"
              placeholder="Entrez votre email"
              value={values.email}
              onChange={handleChange}
            />
          </div>
          <div className="field">
            <label htmlFor="">MOT DE PASSE</label>
            <input
              type="password"
              name="password"
              placeholder="Entrez votre mot de passe"
              value={values.password}
              onChange={handleChange}
            />
          </div>
        </div>
        <span className="forget" onClick={() => setForm("forget")}>
          Mot de passe oublié ?
        </span>
        <button type="submit">SE CONNECTER</button>

        <GoogleButton />
      </form>
    </div>
  );
};

export default LoginForm;
