import luci from "../assets/luci.svg";
import BandeauAvis from "../components/AvisComponents/Home/BandeauAvis";
import LoginForm from "../components/LogsForms/LoginForm";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import RegisterForm from "../components/LogsForms/RegisterForm";
import { motion, AnimatePresence } from "framer-motion"; // Importer motion et AnimatePresence depuis framer-motion
import ForgetForm from "../components/LogsForms/Forget";
import { toast } from "react-toastify";
import { toastOptions } from "../Functions";
const Logs = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Obtenir l'emplacement courant

  const [form, setForm] = useState("login");

  const getAnimationProps = () => {
    return {
      initial: { opacity: 0, x: -50 }, // Animation de sortie
      animate: { opacity: 1, x: 0 }, // Animation d'entrée
      exit: { opacity: 0, x: 50 }, // Animation de sortie
      transition: { duration: 0.5 }, // Durée de l'animation
    };
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const successParam = queryParams.get("error");

    if (successParam === "true") {
      // Do something when success=true is present in the URL
      toast.error("Une erreur s'est produite, réessayez.", toastOptions);
    }
  }, [location]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const successParam = queryParams.get("error2");

    if (successParam === "true") {
      // Do something when success=true is present in the URL
      toast.error(
        "Un compte existe déjà à cette adresse, vérifiez le compte pour vous connecter depuis Google. ",
        toastOptions
      );
    }
  }, [location]);
  return (
    <div className="logspage">
      <div className="form-container">
        <AnimatePresence mode="wait">
          {form === "login" ? (
            <motion.div key="login-form" {...getAnimationProps()}>
              <LoginForm setForm={setForm} navigate={navigate} />
            </motion.div>
          ) : form === "register" ? (
            <motion.div key="register-form" {...getAnimationProps()}>
              <RegisterForm setForm={setForm} />
            </motion.div>
          ) : (
            form === "forget" && (
              <motion.div key="forget-form" {...getAnimationProps()}>
                <ForgetForm setForm={setForm} />
              </motion.div>
            )
          )}
        </AnimatePresence>
      </div>
      <div className="illustration-container">
        <div>
          {" "}
          <h1>REJOINS LA COMMUNAUTÉ</h1>
          <BandeauAvis location={undefined} />
          <h3 className="borderbtn" onClick={() => navigate("/")}>
            Revenir au site <ion-icon name="return-down-back-outline"></ion-icon>
          </h3>
        </div>
      </div>
    </div>
  );
};

export default Logs;
