import Back from "./Backbtn";
import "./forms.css";
import { handlePostRequest } from "../../API/HandleRequests";
import { useState, useContext } from "react";
import { forgetRoute } from "../../utils/APIRoutes";
import { UserDispatchContext } from "../../UserContext";

const ForgetForm = ({ setForm }) => {
  const [values, setValues] = useState({
    email: "",
    password: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await handlePostRequest(forgetRoute, values);
    if (result.status === "ok") {
      setValues({
        email: "",
      });
    }
  };

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  return (
    <div>
      <form action="" onSubmit={handleSubmit}>
        <Back txt={"Retour à l'accueil"} route={"/"} />
        <h1>
          FRANCK<span>.</span>RCRD
        </h1>
        <p>
          Je me souviens du mot de passe ?
          <br />
          <span
            onClick={() => {
              setForm("login");
            }}
          >
            Se connecter
          </span>
        </p>
        <div className="fields">
          <div className="field">
            <label htmlFor="">EMAIL</label>
            <input
              type="text"
              name="email"
              placeholder="Entrez votre email"
              value={values.email}
              onChange={handleChange}
            />
          </div>
        </div>
        <button type="submit">ENVOYER</button>
      </form>
    </div>
  );
};

export default ForgetForm;
