import ContactForm from "../components/ContactForm/ContactForm";

const Contact = () => {
  return (
    <div className="contact fragment">
      <ContactForm />
    </div>
  );
};

export default Contact;
