import "./topScreen.css";
import { useEffect, useState, useContext } from "react";
import { motion, AnimatePresence } from "framer-motion"; // Importer motion et AnimatePresence depuis framer-motion
import { AppContext } from "../../AppContext";

const TopScreen = () => {
  const { topScreenLibs } = useContext(AppContext);

  const [index, setIndex] = useState(0);

  useEffect(() => {
    // Définir l'intervalle de 20 secondes pour changer l'index
    if (topScreenLibs.loaded) {
      const intervalId = setInterval(() => {
        setIndex((prevIndex) => (prevIndex + 1) % topScreenLibs.content.length);
      }, 10000);

      return () => clearInterval(intervalId);
    }

    // Nettoyer l'intervalle lorsque le composant est démonté
  }, [topScreenLibs.loaded, topScreenLibs.content]);

  const styleCharacter = (char) => {
    if (/\d/.test(char) || char === "!") {
      // Vérifier si le caractère est un chiffre en utilisant une expression régulière
      return { color: "#C32727", fontFamily: "Lexgend-Bold" }; // Mettre en rouge les chiffres
    }
    return {}; // Aucun style spécial pour les autres caractères
  };

  return (
    <AnimatePresence mode="wait">
      <div className="topScreen">
        <div className="content">
          <motion.div
            key={index} // Utiliser l'index comme clé pour AnimatePresence
            initial={{ opacity: 0, y: -50 }} // Animation de sortie
            animate={{ opacity: 1, y: 0 }} // Animation d'entrée
            exit={{ opacity: 0, y: 100 }} // Animation de sortie
            transition={{ duration: 0.5 }} // Durée de l'animation
            className="content"
          >
            {topScreenLibs.loaded && (
              <h3>
                {topScreenLibs.content[index] &&
                  topScreenLibs.content[index].lib.split("").map((char, i) => (
                    <span key={i} style={styleCharacter(char)}>
                      {char}
                    </span>
                  ))}
              </h3>
            )}
          </motion.div>
        </div>
      </div>
    </AnimatePresence>
  );
};

export default TopScreen;
