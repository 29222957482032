import { useState, useContext, useEffect } from "react";
import "./admin.css";
import { handleFetchData, handlePostRequest } from "../../API/HandleRequests";
import { newInforoute, removeInfo } from "../../utils/APIRoutes";
import { AppContext, AppDispatchContext } from "../../AppContext";
import { ADD_INFO, REMOVE_INFO } from "../../Reducers/appReducer";
import {
  SET_PRODUCTS,
  SET_PRODUCT_ID,
  SET_SELECTED,
} from "../../Reducers/adminReducer";
import CommentsManage from "./Comments/CommentsManage";
import NewProduct from "./NewProduct";
import { getProductsRoute } from "../../utils/APIRoutes";
import { AdminDispatchContext, AdminContext } from "../../AdminContext";
import ModifyProduct from "./ModifyProduct";
const AdminSection = () => {
  const [info, setInfo] = useState("");

  const dispatch = useContext(AppDispatchContext);
  const { topScreenLibs } = useContext(AppContext);

  const { products, product, selected } = useContext(AdminContext);

  const adminDispatch = useContext(AdminDispatchContext);

  const handleNewInfo = async () => {
    const result = await handlePostRequest(newInforoute, { info });
    if (result === "ok") {
      setInfo("");
      dispatch({ type: ADD_INFO, payload: { lib: info } });
    }
  };

  const handleDelete = async (item) => {
    const result = await handlePostRequest(removeInfo, { item: item.lib });
    if (result === "ok") {
      dispatch({ type: REMOVE_INFO, payload: item });
    }
  };

  useEffect(() => {
    const getProduct = async () => {
      const result = await handleFetchData(getProductsRoute, {});
      if (result.status === "ok") {
        adminDispatch({ type: SET_PRODUCTS, payload: result.data.products });
        adminDispatch({
          type: SET_PRODUCT_ID,
          payload: result.data.products[0].id,
        });
      }
    };
    if (!products.loaded) {
      getProduct();
    }
  }, [products.loaded]);
  return (
    <div className="admin">
      <h3>GÉRER LES INFORMATIONS DU BANDEAU</h3>
      <form action="" className="screenform">
        <div className="fields">
          <div className="field">
            <input
              type="text"
              placeholder="Rentrer une nouvelle information ici..."
              value={info}
              onChange={(e) => setInfo(e.target.value)}
            />
            <h3 className="borderbtn" onClick={() => handleNewInfo()}>
              AJOUTER
            </h3>
          </div>
        </div>
      </form>
      <div className="infos">
        {topScreenLibs.content.map((item, index) => {
          return (
            <div className="info" key={item + "-" + index}>
              <div class="deletebg" onClick={() => handleDelete(item)}></div>
              {item.lib}
            </div>
          );
        })}
      </div>

      <header>
        <h4
          onClick={() => adminDispatch({ type: SET_SELECTED, payload: 0 })}
          className={selected == 0 ? "selected" : ""}
        >
          GÉRER LES COMMENTAIRES
        </h4>
        <h4
          onClick={() => adminDispatch({ type: SET_SELECTED, payload: 1 })}
          className={selected == 1 ? "selected" : ""}
        >
          MODIFIER UN PRODUIT
        </h4>
        <h4
          onClick={() => adminDispatch({ type: SET_SELECTED, payload: 2 })}
          className={selected == 2 ? "selected" : ""}
        >
          AJOUTER UN PRODUIT
        </h4>
      </header>

      {selected == 0 && product && <CommentsManage />}
      {selected == 1 && product && (
        <ModifyProduct
          product={product}
          products={products}
          adminDispatch={adminDispatch}
        />
      )}

      {selected == 2 && <NewProduct />}
    </div>
  );
};

export default AdminSection;
