import { useState, useContext, useEffect } from "react";
import UnVisibles from "./UnVisibles";
import Visibles from "./Visibles";
import { AdminContext, AdminDispatchContext } from "../../../AdminContext";
import { handlePostRequest } from "../../../API/HandleRequests";
import { toogleRoute } from "../../../utils/APIRoutes";
import {
  REMOVE_COMMENT_FROM_VISIBLES,
  REMOVE_COMMENT_FROM_UNVISIBLES,
  ADD_COMMENT_TO_VISIBLES,
  ADD_COMMENT_TO_UNVISIBLES,
  SET_PRODUCT_ID,
} from "../../../Reducers/adminReducer";
const CommentsManage = () => {
  const { comments, products, product } = useContext(AdminContext);
  const adminDispatch = useContext(AdminDispatchContext);

  const [display, setDisplay] = useState(false);

  const handleToogle = async (id, was) => {
    const result = await handlePostRequest(toogleRoute, { id: id });
    if (result === "ok") {
      if (was === "visible") {
        const movedComment = comments.visibles.comments.find(
          (comment) => comment._id === id
        );

        if (movedComment) {
          adminDispatch({ type: REMOVE_COMMENT_FROM_VISIBLES, payload: id });
          adminDispatch({
            type: ADD_COMMENT_TO_UNVISIBLES,
            payload: movedComment,
          });
        }
      } else {
        const movedComment = comments.unvisibles.comments.find(
          (comment) => comment._id === id
        );

        if (movedComment) {
          adminDispatch({ type: REMOVE_COMMENT_FROM_UNVISIBLES, payload: id });
          adminDispatch({
            type: ADD_COMMENT_TO_VISIBLES,
            payload: movedComment,
          });
        }
      }
    }
  };

  useEffect(() => {}, [comments]);

  return (
    <div className="admin-comments">
      {products.loaded && (
        <select
          name=""
          id=""
          value={product.id}
          onChange={(e) =>
            adminDispatch({ type: SET_PRODUCT_ID, payload: e.target.value })
          }
        >
          {products.content.map((item, index) => {
            return (
              <option value={item.id} key={index}>
                {item.name}
              </option>
            );
          })}
        </select>
      )}

      <div className="selectComments">
        <h3
          className={display ? "borderbtn selected" : "borderbtn"}
          onClick={() => setDisplay(true)}
        >
          Visibles
        </h3>
        <h3
          className={!display ? "borderbtn selected" : "borderbtn"}
          onClick={() => setDisplay(false)}
        >
          Invisibles
        </h3>
      </div>
      <div className="container">
        {display ? (
          <Visibles
            visibles={comments.visibles}
            adminDispatch={adminDispatch}
            handleToogle={handleToogle}
            product={product.id}
          />
        ) : (
          <UnVisibles
            unvisibles={comments.unvisibles}
            adminDispatch={adminDispatch}
            handleToogle={handleToogle}
            product={product.id}
          />
        )}
      </div>
    </div>
  );
};

export default CommentsManage;
