import { downloadRoute } from "../../utils/APIRoutes";
import axios from "axios";
import "./Orders.css";
import { toast } from "react-toastify";
import { useEffect } from "react";
import {
  tokenExpire,
  toastOptions,
  pendingOptions,
  updateToast,
} from "../../Functions";

const Orders = ({ products, usr }) => {
  function getAccessTokenFromCookie() {
    const cookies = document.cookie.split(";");
    for (const cookie of cookies) {
      const trimmedCookie = cookie.trim();
      if (trimmedCookie.startsWith("access-token=")) {
        const tokenValue = trimmedCookie.substring("access-token=".length);
        return tokenValue;
      }
    }
    return null; // Ajustez le comportement si le cookie n'est pas trouvé
  }

  const handleDownload = async (product, name) => {
    const id = toast.loading("Traitement de votre requête...", pendingOptions);

    if (!usr.isVerified) {
      toast.update(
        id,
        updateToast(
          "error",
          "Vous devez vérifier votre compte pour faire cette action."
        )
      );
      return;
    }

    try {
      const response = await axios.get(
        downloadRoute,

        {
          responseType: "blob",
          params: {
            product: product,
            token:
              JSON.parse(
                localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)
              ) || getAccessTokenFromCookie(),
          },
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name + ".pdf");
      link.className = "download-link"; // Add this class name

      document.body.appendChild(link);
      link.click();
      link.addEventListener("click", () => window.URL.revokeObjectURL(url));
      toast.dismiss();
    } catch (error) {
      console.log(error.response.status);
      if (error.response.status == 401) {
        toast.dismiss();
        tokenExpire();
      } else if (error.message === "Timeout") {
        toast.update(id, updateToast("error", error.response.data.error));
      } else {
        toast.update(id, updateToast("error", error));
      }
    }
  };

  useEffect(() => {
    const cleanup = () => {
      // Remove the download link element from the DOM
      const link = document.querySelector(".download-link");
      if (link) {
        link.parentNode.removeChild(link);
      }
    };

    return () => {
      cleanup(); // Call the cleanup function when the component is unmounted
    };
  }, []);
  return (
    <div className="orders">
      <h1>VOS ARTEFACTS LÉGENDAIRES</h1>
      {products?.length == 0 && <p>Vous n'avez pas encore d'artefacts...</p>}
      <div className="itemscontainer">
        {products?.map((item) => {
          if(item){
            return (
              <p
                className="item"
                key={item.id}
                onClick={() => handleDownload(item.id, item.name)}
              >
                <div>TÉLÉCHARGER</div>
                {item.name}
              </p>
            );
          }
          
        })}
      </div>
    </div>
  );
};

export default Orders;
