import ebook from "../assets/ebook.webp";
import BandeauAvis from "../components/AvisComponents/Home/BandeauAvis";
import ExtraitBloc from "../components/FreeExtrait/Home/ExtraitBloc";
import { useContext, useRef } from "react";
import { AppContext } from "../AppContext";
import Price from "../components/Price/Price";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const { main_product } = useContext(AppContext);
  const navigate = useNavigate();
  const reference = useRef(undefined);
  const handleScroll = () => {
    if (reference) {
      reference.current.scrollIntoView({
        behavior: "smooth",
        block: "start", // Vous pouvez ajuster cette option si nécessaire
      });
    }
  };
  return (
    <div className="Home fragment">
      <div className="rowSection firstFragment">
        <div className="leftfragment">
          <h1>PRÊT À DEVENIR LE MEILLEUR ?</h1>
          <p>
          Préparez-vous à révolutionner votre physique et votre mental. Cet e-book exclusif est votre ticket pour une transformation impressionnante. Oubliez les doutes, développez une confiance solide. Libérez votre potentiel avec des conseils pratiques comme théoriques.
        
          </p>
          <h3 className="borderbtn" onClick={() => handleScroll()}>
            Voir l'extrait gratuit →
          </h3>

          <div className="price">
            <button onClick={() => navigate("/ebook")} className="buy">
              OBTENIR MON E-BOOK
            </button>
            <Price
              coupon={main_product.metadata.coupon}
              price={main_product.metadata.price}
            />
          </div>
        </div>
        <div className="rightfragment">
          <img src={ebook} alt="MANIFESTE" className="product" />
        </div>
      </div>

      <BandeauAvis />
      <ExtraitBloc
        metadata={main_product.metadata}
        navigate={navigate}
        reference={reference}
      />
      <div className="rowSection FAQContainer">
        <div className="leftfragment">
          <h1>QUI SUIS-JE ?</h1>
          <p>
          En quête de défis autant sur le terrain que dans les méandres de la théorie scientifique, je serai votre guide vers l'optimisation personnelle. Ingénieur mathématicien et passionné de sport, mon parcours se forge à l'intersection de la théorie et de l'action.
          </p>
          <h3 className="borderbtn" onClick={() => navigate("/about")}>
            En savoir plus →
          </h3>
        </div>
        <div className="rightfragment">
          <h1>POURQUOI MON E-BOOK ?</h1>
          <p>
          Cet e-book est né de ma passion pour l'apprentissage et la pédagogie. Il représente une invitation à tous ceux qui aspirent à repousser leurs limites et à façonner leur corps et leur esprit avec une approche théorique autant que pratique de la musculation.
          </p>
          <h3 className="borderbtn" onClick={() => navigate("/ebook")}>
            OBTENIR MON E-BOOK →
          </h3>
        </div>
      </div>
      <div className="centerbloc">
        <h1>ENVIE DE TRAVAILLER AVEC MOI ?</h1>
        <button onClick={() => navigate("/contact")}>ME CONTACTER</button>
      </div>
    </div>
  );
};

export default Home;
