export const SET_USER = "SET_USER";
export const SET_LOG = "SET_LOG";
export const LOG_OUT = "LOG_OUT";

// Reducer
export const userReducer = (state, action) => {
  switch (action.type) {
    case SET_USER:
      const user = action.payload;
      user.loaded = true;
      user.log = true;
      return user;
    case LOG_OUT:
      return { loaded: false, log: false };
    case SET_LOG:
      return { ...state, log: action.payload };
    default:
      return state;
  }
};
