import { useRef } from "react";
import "./style.css";
const NavComponent = ({ ul }) => {
  const ref = useRef(undefined);
  const handleClick = () => {
    ul.current.classList.toggle("active");
    ref.current.classList.toggle("active");
  };
  return (
    <div id="button" ref={ref} onClick={() => handleClick()}>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

export default NavComponent;
