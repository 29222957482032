import { useContext, useRef, useEffect } from "react";
import "./bandeau.css";
import Rate from "../Rate";
import { AppContext } from "../../../AppContext";

const BandeauAvis = ({ location }) => {
  const { previewComments } = useContext(AppContext);



  return (
    <div className="avis-bandeau">
      <div className="bandeau">
      <TrendingScroller speed="slow" >

            <div className="aviscontainer scroller__inner">
          {previewComments.content.map((item) => {
            return (
              <div className="avis" key={item.title+"0"}>
                <h3>{item.title}</h3>
                <Rate rate={item.rate} />
                <p>{item.content}</p>
              </div>
            );
          })}

{previewComments.content.map((item) => {
            return (
              <div className="avis" key={item.title+"0"}>
                <h3>{item.title}</h3>
                <Rate rate={item.rate} />
                <p>{item.content}</p>
              </div>
            );
          })}

        </div>
              
      

        </TrendingScroller>

       
      </div>
      {location && <h3 className="borderbtn">Voir tous les avis →</h3>}
    </div>
  );
};


const TrendingScroller = ({children, speed = "fast"}) => {
    const scrollerRef = useRef(null);

    useEffect(() => {


            const scroller = scrollerRef.current;
            if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
                scroller.setAttribute("data-animated", true);

            }
        
    }, []);

    return (
        <div ref={scrollerRef} className={`scroller`} data-speed="fast">
            {children}
        </div>
    );
};



export default BandeauAvis;
