import React from "react";
import "./rate.css";
import { useState } from "react";
const Rate = ({ rate, handleChange }) => {
  const [hoveredStar, setHoveredStar] = useState(null);

  const renderStar = (index) => {
    const starValue = index + 1;
    let starClass = "star";

    if (hoveredStar !== null && index <= hoveredStar) {
      starClass += " hovered";
    }

    if (starValue <= Math.floor(rate)) {
      starClass += " colored";
    } else if (starValue === Math.ceil(rate)) {
      starClass += " half-colored";
    }

    const handleStarHover = (index) => {
      setHoveredStar(index);
    };

    const handleStarLeave = () => {
      setHoveredStar(null);
    };
    return (
      <div
        key={index}
        className={starClass}
        onMouseEnter={() => handleStarHover(index)}
        onMouseLeave={handleStarLeave}
        onClick={() => {
          handleChange && handleChange(index);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="23"
          height="25"
          viewBox="0 0 23 25"
          fill="none"
        >
          <path d="M19.8088 12.5L22.4069 8.13525C23.1105 6.95312 22.2404 5.46875 20.8439 5.46875H15.6232L12.8913 0.879883C12.5422 0.293457 11.9352 0 11.3283 0C10.7214 0 10.1144 0.293457 9.7653 0.879883L7.03336 5.46875H1.81217C0.415685 5.46875 -0.454432 6.95312 0.249181 8.13525L2.84781 12.5L0.249181 16.8647C-0.454432 18.0469 0.415685 19.5312 1.81217 19.5312H7.03336L9.7653 24.1201C10.1144 24.7065 10.7214 25 11.3283 25C11.9352 25 12.5422 24.7065 12.8913 24.1201L15.6232 19.5312H20.8444C22.2409 19.5312 23.111 18.0469 22.4074 16.8647L19.8088 12.5ZM19.1847 8.20312L18.2179 9.82715L17.2511 8.20312H19.1847ZM16.6266 12.5L14.0685 16.7969H8.58805L6.02994 12.5L8.58756 8.20312H14.0685L16.6266 12.5ZM11.3283 3.59961L12.4411 5.46875H10.216L11.3283 3.59961ZM3.47184 8.20312H5.40543L4.43863 9.82715L3.47184 8.20312ZM3.47184 16.7969L4.43863 15.1729L5.40543 16.7969H3.47184ZM11.3283 21.4004L10.2155 19.5312H12.4406L11.3283 21.4004ZM17.2506 16.7969L18.2174 15.1729L19.1847 16.7969H17.2506Z" />

          {/* Half Star */}
          {starValue === Math.ceil(rate) && rate % 1 === 0.5 && (
            <path d="M19.8088 12.5L22.4069 8.13525C23.1105 6.95312 22.2404 5.46875 20.8439 5.46875H15.6232L12.8913 0.879883C12.5422 0.293457 11.9352 0 11.3283 0C10.7214 0 10.1144 0.293457 9.7653 0.879883L7.03336 5.46875H1.81217C0.415685 5.46875 -0.454432 6.95312 0.249181 8.13525L2.84781 12.5L0.249181 16.8647C-0.454432 18.0469 0.415685 19.5312 1.81217 19.5312H7.03336L9.7653 24.1201C10.1144 24.7065 10.7214 25 11.3283 25C11.9352 25 12.5422 24.7065 12.8913 24.1201L15.6232 19.5312H20.8444C22.2409 19.5312 23.111 18.0469 22.4074 16.8647L19.8088 12.5ZM19.1847 8.20312L18.2179 9.82715L17.2511 8.20312H19.1847ZM16.6266 12.5L14.0685 16.7969H8.58805L6.02994 12.5L8.58756 8.20312H14.0685L16.6266 12.5ZM11.3283 3.59961L12.4411 5.46875H10.216L11.3283 3.59961ZM3.47184 8.20312H5.40543L4.43863 9.82715L3.47184 8.20312ZM3.47184 16.7969L4.43863 15.1729L5.40543 16.7969H3.47184ZM11.3283 21.4004L10.2155 19.5312H12.4406L11.3283 21.4004ZM17.2506 16.7969L18.2174 15.1729L19.1847 16.7969H17.2506Z" />
          )}
        </svg>
      </div>
    );
  };

  const stars = Array.from({ length: 5 }, (_, index) => renderStar(index));

  return <div className="rate">{stars}</div>;
};

export default Rate;
