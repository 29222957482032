import { useState, useRef, useEffect } from "react";
import { changeRoute, resendRoute } from "../../utils/APIRoutes";
import { handlePostRequest } from "../../API/HandleRequests";
const ChangePassword = ({ user }) => {
  const [values, setValues] = useState({
    email: user.email,
    old: "",
    password: "",
    password2: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (user.provider) {
      return;
    }
    const result = await handlePostRequest(changeRoute, values);
    if (result === "ok") {
      setValues({
        ...values,
        old: "",
        password: "",
        password2: "",
      });

      setDisplay(false);
    }
  };

  const [display, setDisplay] = useState(false);

  const progress = useRef(undefined);
  useEffect(() => {
    const passwordLength = values.password.length;
    progress.current.classList.remove("bad", "ok", "good");

    if (passwordLength > 1 && passwordLength < 8) {
      progress.current.classList.add("bad");
    } else if (passwordLength >= 8 && passwordLength < 12) {
      progress.current.classList.add("ok");
    } else if (passwordLength >= 12) {
      progress.current.classList.add("good");
    }
  }, [values.password]);

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const handleResend = async () => {
    const result = await handlePostRequest(resendRoute, {});
  };
  return (
    <>
      {user.isVerified ? (
        <div className="verifycontainer">
          <h4>
            {" "}
            <ion-icon name="checkmark-circle"></ion-icon>
            Votre compte semble prêt pour l’apocalypse.
          </h4>
        </div>
      ) : (
        <div className="verifycontainer">
          <h4>
            <ion-icon name="alert-circle"></ion-icon> Votre compte ne semble pas
            encore prêt pour l’apocalypse.
          </h4>
          <h3 className="borderbtn" onClick={() => handleResend()}>
            Renvoyer un mail.
          </h3>
        </div>
      )}
      <form action="" onSubmit={handleSubmit} className="changepwd">
        <div className="fields">
          <div className="field">
            <label htmlFor="">ADRESSE MAIL</label>
            <input type="text" value={values.email} contentEditable={false} />
          </div>

          <div className={!display ? "hidded-form" : "hidded-form display"}>
            <div className="field">
              <label htmlFor="">ANCIEN MOT DE PASSE</label>
              <input
                type="password"
                name="old"
                value={values.old}
                onChange={handleChange}
              />
            </div>

            <div className="field">
              <label htmlFor="">NOUVEAU MOT DE PASSE</label>
              <input
                type="password"
                name="password"
                value={values.password}
                onChange={handleChange}
              />
              <div className="strongpassword">
                <div className="progress" ref={progress}></div>
              </div>
            </div>
            <div className="field">
              <label htmlFor="">RETAPER LE MOT DE PASSE</label>
              <input
                type="password"
                name="password2"
                value={values.password2}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        {!user.provider && (
          <div className="row">
            <h3 className="borderbtn" onClick={() => setDisplay(!display)}>
              {!display ? "CHANGER DE MOT DE PASSE" : "ANNULER"}
            </h3>
            {display && <button type="submit">CONFIRMER</button>}
          </div>
        )}
      </form>
    </>
  );
};

export default ChangePassword;
