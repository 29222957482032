import ChangeForm from "../components/LogsForms/ChangePassword";

const Logs = () => {
  return (
    <div className="logspage">
      <div className="form-container">
        <ChangeForm />
      </div>
    </div>
  );
};

export default Logs;
