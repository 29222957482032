import { toast, Slide } from "react-toastify";

export const toastOptions = {
  position: "bottom-right",
  autoClose: 6000,
  transition: Slide,
  pauseOnHover: true,
  draggable: false,
  theme: "dark",
};

export const pendingOptions = {
  position: "bottom-right",
  autoClose: false,
  transition: Slide,
  closeOnClick: false, // Disable closing the toast on click
  theme: "dark",
};

export const updateToast = (type, render) => {
  return {
    position: "bottom-right",
    autoClose: 6000,
    transition: Slide,
    pauseOnHover: true,
    draggable: false,
    theme: "dark",
    type: type,
    isLoading: false,
    render: render,
    closeOnClick: true,
    delay: 700, // Disable closing the toast on click
  };
};

export const tokenExpire = () => {
  toast.info("Votre session viens d'expirer, reconnectez-vous..", toastOptions);

  setTimeout(() => {
    localStorage.removeItem(process.env.REACT_APP_LOCALHOST_KEY);
    const pastDate = new Date(0).toUTCString();
    document.cookie = `access-token=; expires=${pastDate}; path=/;`;
    document.cookie = `refresh-token=; expires=${pastDate}; path=/;`;
    document.cookie = `logged_in=; expires=${pastDate}; path=/;`;
    window.location.replace("/logs");
  }, 3500);
};
